import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { logToServer, logOutFromServer } from './authAPI';
//import { queryServer } from '../../app/utils';

const initialState = {
    user: {},
    authenticated: false,
    pending: false,
    error: false
};

// async thunks
export const login = createAsyncThunk(
    'auth/login',  
    async (_, { rejectWithValue }) => {
        try {
            const response = await logToServer();
            if (! response.ok) {
                throw new Error(`Received error ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (e) {
            return rejectWithValue(e.message);
        }
    }
);

export const logout = createAsyncThunk(
    'auth/logout',  
    async (_, { rejectWithValue }) => {
        try {
            const response = await logOutFromServer();
            if (! response.ok) {
                throw new Error(`Received error ${response.status}`);
            }
            const data = response.status;
            return data;
        } catch (e) {
            return rejectWithValue(e.message);
        }
    }
);

// slice object
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearState: (state) => {
            state.user = {};
            state.authenticated = false;
            state.pending = false;
            state.error = false;
        }
    },
    extraReducers: (builder) => {
        builder
        // login
            .addCase(login.pending, (state) => {
                state.user = {};
                state.authenticated = false;
                state.pending = true;
                state.error = false;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.user = action.payload.user;
                state.authenticated = true;
                state.pending = false;
                state.error = false;
            })
            .addCase(login.rejected, (state) => {
                state.user = {};
                state.authenticated = false;
                state.pending = false;
                state.error = true;
            })

        // logout
            .addCase(logout.pending, (state) => {
                //state.user = {};
                state.authenticated = true;
                state.pending = true;
                state.error = false;
            })
            .addCase(logout.fulfilled, (state) => {
                state.user = {};
                state.authenticated = false;
                state.pending = false;
                state.error = false;
            })
            .addCase(logout.rejected, (state) => {
                //state.user = {};
                state.authenticated = true;
                state.pending = false;
                state.error = true;
            });
    }
});

// selectors
export const selectAuthenticated = (state) => state.auth.authenticated;
export const selectUser = (state) => state.auth.user;

// exports
export const { 
    clearState
} = authSlice.actions;
export default authSlice.reducer;
