import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
//import './expediente.css';
import { selectAuthenticated } from '../auth/authSlice';
import { loadData, unselectAll } from './buscar/buscarUsuarioSlice';
//import { STATUS, loadExpediente } from './expedienteSlice';
import Login from '../auth/Login';
import ListaUsuarios from './buscar/ListaUsuarios';
import BuscarUsuario from './buscar/BuscarUsuario';
import { selectBuscarUsuario, selectAll } from './buscar/buscarUsuarioSlice';
//import Spinner from '../../components/Spinner';


export default function AdminUsuarios () {
    const navigate = useNavigate();
    const userAuthenticated = useSelector(selectAuthenticated);
    const { todos, email } = useSelector(selectBuscarUsuario);
    /* useEffect(() => {
        if (!userAuthenticated) {
            navigate('/login');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userAuthenticated]); */
    
    const dispatch = useDispatch();

    useEffect(() => {
        loadList();
    }, [todos]);

    function loadList(){
        dispatch( loadData({ 
            todos, 
            termino: email }) 
        );
    }

    function onClickHandler(e) {
        e.preventDefault();
        const target = e.target;
        if (target.id === 'todos') {
            dispatch(selectAll());
            loadList();
        } else if (target.id === 'crear') {
            navigate('/usuario');
        } else if (target.id === 'responsables') {
            navigate('/usuarios/responsables');
        } else {
            dispatch(unselectAll());
            loadList();
        }
    }

    if (!userAuthenticated) {
        return <Login />;
    }

    return (
        <> 
            <div className='title'>
                <h2>Administración de usuarios</h2>
            </div>
            <button id='volver' onClick={() => navigate('/')}>Atrás</button>
            <BuscarUsuario onClickHandler={onClickHandler} />
            <div className="buttons-fullpage">
                <button id='todos' onClick={onClickHandler}>Listar todos</button>
                <button id='responsables' onClick={onClickHandler}>Gestionar responsables de plataforma</button>
            </div>
            <ListaUsuarios />
        </>
    );
}