/* eslint-disable no-undef */

export const traeUsuario = async (email) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usuarios/${email}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Credentials': true
        }
    });
    return response;
};

export const enviaNuevoUsuario = async (data) => {
    // HACE FALTA DISTINGUIR ENTRE ENVIAR UN EXPEDIENTE
    // NUEVO O EDICIONES DE UNO EXISTENTE
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usuarios/`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        //mode: 'cors', // no-cors, *cors, same-origin
        //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Credentials': true
        },
        //redirect: 'follow', // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response;
};

export const actualizaUsuario = async (data) => {
    const email = data.email;
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usuarios/${email}`, {
        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        //mode: 'cors', // no-cors, *cors, same-origin
        //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Credentials': true
        },
        //redirect: 'follow', // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response;
};

export const borraUsuario = async (email) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usuarios/${email}`, {
        method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
        credentials: 'include', // include, *same-origin, omit
        headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Credentials': true
        }
    });
    return response;
};

export const buscaUsuarios = async ({todos, termino}) => {
    /* fetch('https://example.com?' + new URLSearchParams({
        foo: 'value',
        bar: 2,
    })) */
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usuarios?`  + new URLSearchParams({
        todos,
        termino
    }), {
        method: 'GET',
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Credentials': true
        }
    });
    return response;
};

export const traeResponsables = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/responsables`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Credentials': true
        }
    });
    return response;
};

export const cambiaResponsable = async (data) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/responsables`, {
        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        //mode: 'cors', // no-cors, *cors, same-origin
        //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Credentials': true
        },
        //redirect: 'follow', // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response;
};

export const compruebaResponsable = async (email) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/responsables/usuario/${email}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Credentials': true
        }
    });
    return response;
};

/*
export const buscaUsuarios = async (data) => {

    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usuarios/`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Credentials': true
        },
        body: JSON.stringify(data)
    });
    return response;
};
 */
