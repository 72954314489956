/* eslint-disable no-undef */

export const traeNumexpedientes = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/expediente/totalnum`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Credentials': true
        }
    });
    return response;
};
