import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editInvolucrada } from './informeSlice';
import AgregarInvolucrada from './AgregarInvolucrada';

// eslint-disable-next-line react/prop-types
export default function PersonasInvolucradas ({editable}) {
    const dispatch = useDispatch();
    const { informe } = useSelector((state) => state.informe);

    function onChangeInvolucradaHandler({target}){
        // e.g.: target.id = nombre_involucrada-0
        const idArray = target.id.split('-');
        const name = idArray[0];
        const pos = idArray[1];
        const value = target.value;
        dispatch(editInvolucrada({
            pos,
            name,
            value
        }));
    }

    function printPersonaInvolucrada(involucrada, i){
        return (
            <div key={`${i}`} className="involucrada">
                <p>{`${i+1}.`}</p>
                <div className="involucrada-data">
                    <label htmlFor={`nombre_involucrada-${i}`}>Nombre:</label>
                    { editable
                        ? <input type="text" id={`nombre_involucrada-${i}`} name={`nombre_involucrada-${i}`} 
                            value={informe.involucradas[i].nombre_involucrada || ''}
                            onChange={onChangeInvolucradaHandler}/>
                        : <input type="text" id={`nombre_involucrada-${i}`} name={`nombre_involucrada-${i}`} 
                            value={informe.involucradas[i].nombre_involucrada}/>
                    }

                    <label htmlFor={`funcion_involucrada-${i}`}>Puesto de trabajo / función:</label>
                    { editable
                        ? <input type="text" id={`funcion_involucrada-${i}`} name={`funcion_involucrada-${i}`} 
                            value={informe.involucradas[i].funcion_involucrada || ''}
                            onChange={onChangeInvolucradaHandler}/>
                        : <input type="text" id={`funcion_involucrada-${i}`} name={`funcion_involucrada-${i}`} 
                            value={informe.involucradas[i].funcion_involucrada}/>
                    }
                
                    <label htmlFor={`relacion_involucrada-${i}`}>Relación con la persona afectada:</label>
                    { editable
                        ? <input type="text" id={`relacion_involucrada-${i}`} name={`relacion_involucrada-${i}`} 
                            value={informe.involucradas[i].relacion_involucrada || ''}
                            onChange={onChangeInvolucradaHandler}/>
                        : <input type="text" id={`relacion_involucrada-${i}`} name={`relacion_involucrada-${i}`} 
                            value={informe.involucradas[i].relacion_involucrada}/>
                    }
                </div>
            </div>
        );
    }

    return (
        <div className="buildable-list">
            <div className='involucradas'>
                { informe.involucradas !== undefined && informe.involucradas.length > 0
                    ? informe.involucradas.map((involucrada, i)=> (
                        printPersonaInvolucrada(involucrada, i)
                    ))
                    : <p>No se ha registrado ninguna persona involucrada.</p>
                } 
            </div><br/><br/>
            { editable
                ? <AgregarInvolucrada />
                : ''
            }
        </div>
    );
}