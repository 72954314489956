/* eslint-disable no-undef */
//const SERVER_PORT = 'http://localhost:3001/expedientes';
//const SERVER_PORT = 'https://54.74.26.246:443/expedientes';
//import { SERVER_PORT } from '../../app/utils';

export const actualizaExpediente = async (data) => {
    const num = data.numero;
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/expediente/${num}`, {
        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        //mode: 'cors', // no-cors, *cors, same-origin
        //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Credentials': true
        },
        //redirect: 'follow', // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response;
};

export const traeExpediente = async (num) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/expediente/${num}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Credentials': true
        }
    });
    return response;
};

// en principio, solo para tests:
export const borraExpediente = async (num) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/expediente/${num}`, {
        method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
        credentials: 'include', // include, *same-origin, omit
        headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Credentials': true
        }
    });
    return response;
};

// EN PRINCIPIO YA NO HACE FALTA
export const enviaNuevoExpediente = async (data) => {
    // HACE FALTA DISTINGUIR ENTRE ENVIAR UN EXPEDIENTE
    // NUEVO O EDICIONES DE UNO EXISTENTE
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/expediente/`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        //mode: 'cors', // no-cors, *cors, same-origin
        //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Credentials': true
        },
        //redirect: 'follow', // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response;
};


export const traeResponsablePlataforma = async (plataforma) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/responsables/plataforma/${plataforma}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Credentials': true
        }
    });
    return response;
};
