/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFiles, updateFile, clearFile, updateStatus } from './documentoSlice';
import { subeDocumento, borraDocumento } from './documentoAPI';

export default function SubirDocumento() {
    const dispatch = useDispatch();
    const { file, loadingStatus } = useSelector(selectFiles).new;
    
    async function uploadFile(ref) {
        if (!ref) {
            return;
        }
        dispatch(updateStatus({ 
            which: 'new',
            loadingStatus: 'uploading' 
        }));
        const formData = new FormData();
        formData.append('documento', ref);

        try {
            const response = await subeDocumento(formData);
            if (response.ok) {
                dispatch(updateStatus({ 
                    which: 'new',
                    loadingStatus: 'success'
                }));
                const filename = await response.text();
                dispatch(updateFile({
                    which: 'new',
                    file: filename.replace('\\', '/')
                }));
            } else {
                const e = await response.text();
                throw new Error(e);
            }
                
        } catch (error) {
            dispatch(updateStatus({
                which: 'new',
                loadingStatus: 'error: '+error 
            }));
            dispatch(clearFile({ which: 'new' }));
        }
    }

    async function removeFile() {
        if (!file) {
            return true;
        }
        
        try {
            const response = await borraDocumento(file);
            if (response.ok) {
                dispatch(clearFile({ which: 'new' }));
                return true;
            } else {
                const e = await response.text();
                throw new Error(e);
            }
                
        } catch (error) {
            dispatch(updateStatus({
                which: 'new',
                loadingStatus: 'error: '+error 
            }));
            return false;
        }
    }
    
    async function onClickHandler(e) {
        e.preventDefault();
        const input = document.getElementById('documento_actuacion');
        if (input.files){
            if (!file || (file && removeFile())) {
                await uploadFile(input.files[0]);
            }
        }
    } 


    function DocStatus ({status}) {
        if (status === 'success') {
            return (<p className='docstatus'>
            ✅ Documento cargado correctamente: 
                <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_SERVER_URL}/documentos/${file}`}>Ver</a>
            </p>);
        } else if (status === 'error') {
            return <p className='docstatus'>❌ No se pudo cargar el documento.</p>;
        } else if (status === 'uploading'){
            return <p className='docstatus'>⏳ Cargando documento...</p>;
        } else {
            return '';
        }
    }


    return (
        <div className='docform'>
            <label htmlFor="documento_actuacion">Documento adjunto:</label>
            <input type="file" id="documento_actuacion" name="documento_actuacion"/>
            <button onClick={onClickHandler}>Subir documento</button>
            <DocStatus status={loadingStatus} />
        </div>
    );
}