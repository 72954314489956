import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { traeInforme, enviaNuevoInforme } from './informeAPI';
import { queryServer } from '../../app/utils';

export const STATUS = {
    NEW: 'new',
    SAVING: 'saving',
    SAVED: 'saved',
    LOADING: 'loading',
    COULDNT_LOAD: 'couldn\'t load',
    COULDNT_SAVE: 'couldn\'t save',
    NO_ERROR: 0
};

const initialState = {
    informe: {
        escenario: '1',
        involucradas: [],
        profesionales: []
    },
    status: STATUS.NEW,
    error: STATUS.NO_ERROR
};


// async thunks
export const loadInforme = createAsyncThunk(
    'informe/loadInforme',  
    async (num, { rejectWithValue }) => {
        const response = await queryServer(traeInforme, num, rejectWithValue);
        const data = await response.json();
        return data;
    }
);

export const persistInforme = createAsyncThunk(
    'informe/persistInforme',
    async (_, { rejectWithValue, getState }) => {
        const { informe } = getState().informe;
        const response = await queryServer(enviaNuevoInforme, informe, rejectWithValue);
        const data = await response.json();
        return data;
    }
);

// slice object
export const informeSlice = createSlice({
    name: 'informe',
    initialState,
    reducers: {
        clearInforme: (state) => {
            state.informe = {
                escenario: '1',
                involucradas: [],
                profesionales: []
            };
            state.status = STATUS.NEW;
            state.error = STATUS.NO_ERROR;
        },
        editInforme: (state, action) => {
            // NO: 
            // si hay alguna edición es en la pantalla donde
            // se editan todos los datos, así que bien podemos
            // pasar el objeto completo y sobreescribirlo
            // con los cambios que pueda haber en los distintos campos
            //state.data = action.payload;
            // SÍ:
            // actualizamos campos individuales
            const { key, value } = action.payload;
            state.informe[key] = value;
            // state.status = STATUS.NEW; // se mantiene en NEW hasta que se guarda
        },
        editInvolucrada: (state, action) => {
            const { pos, name, value } = action.payload;
            const involucrada = state.informe.involucradas[pos];
            involucrada[name] = value;
            // state.status = STATUS.NEW; // se mantiene en NEW hasta que se guarda
        },
        addInvolucrada: (state, action) => {
            // las personas involucradas se añaden una a una en un formulario
            // individual
            state.informe.involucradas.push(action.payload);
            // state.status = STATUS.NEW; // se mantiene en NEW hasta que se guarda
        },
        editProfesional: (state, action) => {
            const { pos, name, value } = action.payload;
            const profesional = state.informe.profesionales[pos];
            profesional[name] = value;
            // state.status = STATUS.NEW; // se mantiene en NEW hasta que se guarda
        },
        addProfesional: (state, action) => {
            // las profesionales se añaden una a una en un formulario
            // individual
            state.informe.profesionales.push(action.payload);
            // state.status = STATUS.NEW; // se mantiene en NEW hasta que se guarda
        }
    },
    extraReducers: (builder) => {
        builder
        // loadInforme
            .addCase(loadInforme.pending, (state) => {
                state.informe = {
                    escenario: '1',
                    involucradas: [],
                    profesionales: []
                };
                state.status = STATUS.LOADING;
                state.error = STATUS.NO_ERROR;
            })
            .addCase(loadInforme.fulfilled, (state, action) => {
                state.informe = action.payload;
                state.status = STATUS.SAVED;
                state.error = STATUS.NO_ERROR;
            })
            .addCase(loadInforme.rejected, (state) => {
                state.status = STATUS.NEW;
                state.error = STATUS.COULDNT_LOAD;
            })
      
        // persistInforme
            .addCase(persistInforme.pending, (state) => {
                state.status = STATUS.SAVING;
                state.error = STATUS.NO_ERROR;
            })
            .addCase(persistInforme.fulfilled, (state, action) => {
                state.informe.numero = action.payload.numero;
                state.status = STATUS.SAVED;
                state.error = STATUS.NO_ERROR;
            })
            .addCase(persistInforme.rejected, (state) => {
                state.status = STATUS.NEW;
                state.error = STATUS.COULDNT_SAVE;
            });
    }
});

// selectors
export const selectInforme = (state) => state.informe.informe;

// exports
export const { 
    clearInforme,
    editInforme,
    editInvolucrada,
    addInvolucrada,
    editProfesional,
    addProfesional
} = informeSlice.actions;
export default informeSlice.reducer;
