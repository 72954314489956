/* eslint-disable linebreak-style */
import './App.css';
import React from 'react';
import { Route, RouterProvider, createBrowserRouter, 
    createRoutesFromElements } from 'react-router-dom';

import Root from '../components/Root';
import Index from '../components/Index';
import NuevoInforme from '../features/informe/NuevoInforme';
import VerInforme from '../features/informe/VerInforme';
import Expediente from '../features/expediente/Expediente';
import EditarExpediente from '../features/expediente/EditarExpediente';
import VerExpediente from '../features/expediente/VerExpediente';
import Documento from '../components/Documento';
import Login from '../features/auth/Login';
import Search from '../features/search/Search';
import AdminUsuarios from '../features/usuario/AdminUsuarios';
import AdminResponsables from '../features/usuario/AdminResponsables';
import Usuario from '../features/usuario/datos/Usuario';

const router = createBrowserRouter( createRoutesFromElements(
    /* Wrap this Root Route to create Router here */
    <Route path="/" element={ <Root/> }>
        <Route path="login" element={ <Login /> } />

        <Route path="buscar" element={ <Search /> } />

        <Route path="informe" element={ <NuevoInforme /> } />
        <Route path="informe/:num" element={ <VerInforme /> } />

        <Route path="expediente/:num" element={ <Expediente/> } />
        <Route path="expediente/:num/ver" element={ <VerExpediente/> } />
        <Route path="expediente/:num/editar" element={ <EditarExpediente/> } />

        <Route path="documentos/:folder/:doc" element={ <Documento /> } />

        <Route path="usuario" element={ <Usuario /> } />
        <Route path="usuario/:login" element={ <Usuario/> } />
        <Route path="usuarios" element={ <AdminUsuarios/> } />
        <Route path="usuarios/responsables" element={ <AdminResponsables/> } />


        <Route index element={ <Index/> } />
    </Route>
));

// TODO: PARA CUANDO SESIONES:
// window.location.reload()
// AL HACER LOGOUT

function App() {
    return (
        <RouterProvider router={router} />
    );
}

export default App;
