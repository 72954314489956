import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './informe.css';
import { selectAuthenticated } from '../auth/authSlice';
import { STATUS, loadInforme } from './informeSlice';
import Spinner from '../../components/Spinner';
import Login from '../auth/Login';
import PersonasInvolucradas from './PersonasInvolucradas';
import Profesionales from './Profesionales';
import { escenarios } from '../../app/utils.js';

export default function VerInforme () {
    const navigate = useNavigate();
    const userAuthenticated = useSelector(selectAuthenticated);
    /* useEffect(() => {
        if (!userAuthenticated) {
            navigate('/login');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userAuthenticated]); */

    const { num } = useParams();
    const dispatch = useDispatch();
    const { informe, status, error } = useSelector((state) => state.informe);

    useEffect(()=>{
        if (!isNaN(parseInt(num))) {
            dispatch(loadInforme(num));
        } else {
            alert('No se puede cargar el informe sin disponer del número de notificación.');
            navigate('/');
        }
    }, [num]);

    if (!userAuthenticated) {
        return <Login />;
    }

    if (status === STATUS.LOADING) {
        return <Spinner/>;
    }

    if (error === STATUS.COULDNT_LOAD) {
        return (
            <> 
                <div className='title'>
                    <h2>ERROR: No hay ningún informe nº {num}</h2>
                </div>
                <button id='volver' onClick={() => navigate('/')}>Volver a inicio</button>
            </>
        );
    }

    return (
        <> 
            <div className='title'>
                <h2>Informe de notificación interno</h2>
                <h3>Comunicación de dudas, sospechas o certezas sobre posibles
                situaciones de violencia o trato inadecuado a niños, niñas,
                adolescentes y/o personas en situación de vulnerabilidad
                </h3>
            </div>
            <button id="volver" onClick={() => navigate(-1)}>Atrás</button>
            <div className='content'>
                <h3>Confidencialidad:</h3>
                <p>Toda la información contenida en el presente Informe será tratada 
                con la confidencialidad exigida por el Protocolo de Actuación 
                de la entidad y la normativa vigente en materia de datos 
                de carácter personal.</p>
                <form>
                    <h3>Tipo de escenario:</h3>
                    <input readOnly type="text" id="escenario" name="escenario" value={escenarios[parseInt(informe.escenario)-1]}/>
                    <h3>Datos de registro:</h3>
                    <label htmlFor="fecha">Fecha:</label>
                    <input readOnly type="date" id="fecha" name="fecha" value={informe.fecha}/>
                    <label htmlFor="hora">Hora:</label>
                    <input readOnly type="text" id="hora" name="hora" value={informe.hora}/>
                    <label htmlFor="numero">Nº de expediente:</label>
                    <input readOnly type="text" id="numero" name="numero" value={informe.numero}/>
                    <label htmlFor="plataforma">Plataforma:</label>
                    <input readOnly type="text" id="plataforma" name="plataforma" value={informe.plataforma}/>

                    <h3>Datos de la persona notificante:</h3>
                    <label htmlFor="nombre_notificante">Nombre:</label>
                    <input type="text" readOnly id="nombre_notificante" name="nombre_notificante"
                        value={informe.nombre_notificante}/>
                    <label htmlFor="funcion_notificante">Puesto de trabajo / función:</label>
                    <input type="text" readOnly id="funcion_notificante" name="funcion_notificante"
                        value={informe.funcion_notificante}/>
                    <label htmlFor="relacion_notificante">Relación con la persona afectada*: <span className="clarification">(* niño, niña, adolescente o persona en situación de vulnerabilidad)</span></label>
                    <input type="text" readOnly id="relacion_notificante" name="relacion_notificante"
                        value={informe.relacion_notificante}/>
                    <label htmlFor="telefono_notificante">Teléfono:</label>
                    <input type="text" readOnly id="telefono_notificante" name="telefono_notificante"
                        value={informe.telefono_notificante}/>
                    <label htmlFor="email_notificante">E-mail:</label>
                    <input type="text" readOnly id="email_notificante" name="email_notificante"
                        value={informe.email_notificante}/>

                    <h3>Datos de la persona que cumplimenta el informe (en caso de no ser la notificante):</h3>
                    <label htmlFor="nombre_cumplimenta">Nombre:</label>
                    <input type="text" readOnly id="nombre_cumplimenta" name="nombre_cumplimenta"
                        value={informe.nombre_cumplimenta}/>
                    <label htmlFor="funcion_cumplimenta">Función dentro del Sistema de Protección Interno:</label>
                    <input type="text" readOnly id="funcion_cumplimenta" name="funcion_cumplimenta"
                        value={informe.funcion_cumplimenta}/>
                    <label htmlFor="telefono_cumplimenta">Teléfono:</label>
                    <input type="text" readOnly id="telefono_cumplimenta" name="telefono_cumplimenta"
                        value={informe.telefono_cumplimenta}/>
                    <label htmlFor="email_cumplimenta">E-mail:</label>
                    <input type="text" readOnly id="email_cumplimenta" name="email_cumplimenta"
                        value={informe.email_cumplimenta}/>

                    <h3>Datos de la persona afectada:</h3>
                    <label htmlFor="nombre_afectada">Nombre:</label>
                    <input type="text" readOnly id="nombre_afectada" name="nombre_afectada"
                        value={informe.nombre_afectada}/>
                    <label htmlFor="edad_afectada">Edad:</label>
                    <input type="text" readOnly id="edad_afectada" name="edad_afectada"
                        value={informe.edad_afectada}/>
                    <label htmlFor="sexo_afectada">Sexo:</label>
                    <input type="text" readOnly id="sexo_afectada" name="sexo_afectada"
                        value={informe.sexo_afectada}/>
                    <label htmlFor="programa_afectada">Programa en el que participa:</label>
                    <input type="text" readOnly id="programa_afectada" name="programa_afectada"
                        value={informe.programa_afectada}/>
                
                    <h3>Datos de la/s persona/s involucrada/s:</h3>
                    <PersonasInvolucradas editable={false}/>
                    <br/><br/>

                    <h3>Origen o fuente de la detección:</h3>
                    <label htmlFor="deteccion">¿Cómo tuvo noticia de la situación?</label>
                    <div className='textarea-content' id="deteccion">
                        <pre>{informe.deteccion || ''}</pre>
                    </div>

                    <h3>Descripción de la consulta, situación o de los motivos de sospecha:</h3>
                    <label htmlFor="fecha_hora_suceso">Día y hora del suceso:</label>
                    <input type="text" readOnly id="fecha_hora_suceso" name="fecha_hora_suceso"
                        value={informe.fecha_hora_suceso}/>
                    <label htmlFor="involucradas_suceso">Personas involucradas en esta situación concreta:</label>
                    <div className='textarea-content' id="involucradas_suceso">
                        <pre>{informe.involucradas_suceso || ''}</pre>
                    </div>
                    <label htmlFor="que_donde_suceso">Qué ha sucedido y dónde, de la situación concreta:</label>
                    <div className='textarea-content' id="que_donde_suceso">
                        <pre>{informe.que_donde_suceso || ''}</pre>
                    </div>

                    <h3>¿Qué se ha hecho al respecto?</h3>
                    <p>¿Qué actuaciones se han llevado a cabo y quién las ha llevado a cabo?</p>
                    <p>Por parte de la persona notificante, por parte de otras personas...</p>
                    <label htmlFor="actuaciones_menor">Con respecto a la persona afectada:</label>
                    <div className='textarea-content' id="actuaciones_menor">
                        <pre>{informe.actuaciones_menor || ''}</pre>
                    </div>
                    <label htmlFor="actuaciones_familia">Con respecto a la familia:</label>
                    <div className='textarea-content' id="actuaciones_familia">
                        <pre>{informe.actuaciones_familia || ''}</pre>
                    </div>
                    <label htmlFor="actuaciones_otros">Con respecto a...</label>
                    <div className='textarea-content' id="actuaciones_otros">
                        <pre>{informe.actuaciones_otros || ''}</pre>
                    </div>
                    <label htmlFor="comunicacion">¿Se ha hecho comunicación a algún servicio público?</label>
                    <div className='textarea-content' id="comunicacion">
                        <pre>{informe.comunicacion || ''}</pre>
                    </div>

                    <h3>Datos de otras posibles profesionales que trabajan con la familia:</h3>
                    <Profesionales editable={false}/>
                    <br/><br/>

                    <h3>Observaciones:</h3>
                    <div className='textarea-content' id="observaciones">
                        <pre>{informe.observaciones || ''}</pre>
                    </div>
                </form>
            </div>
            <button onClick={() => navigate(-1)}>Atrás</button>
        </>
    );
}