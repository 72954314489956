import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS, clearResults } from './searchSlice';
import Spinner from '../../components/Spinner';
import { Link } from 'react-router-dom';

export default function SearchResults () {
    const { results, status, error } = useSelector((state) => state.search);
    const dispatch = useDispatch();

    // eslint-disable-next-line no-unused-vars
    function onClickHandler({target}) {
        // limpiar resultados
        dispatch(clearResults());
    }

    if (status === STATUS.LOADING) {
        return <Spinner/>;
    }

    if (error === STATUS.COULDNT_LOAD) {
        return (
            <div className='search-results'>
                <h3>Resultados:</h3>
                <p>No se pudo completar la búsqueda.</p>
            </div>
        );
    }

    if (status === STATUS.NO_DATA) {
        return;
    }

    return (
        <div className='search-results'> 
            <div className='results-title' >
                <h3>Resultados:</h3>
            </div>

            <div className="results" id="informes">
                {
                    results.informes.length === 0
                        ? <p>No se encontraron informes coincidentes.</p>
                        : <div>
                            {/*<h4>Informes:</h4>*/}
                            <p>{results.informes.length} informes:</p>
                            <ul>
                                { results.informes.map((doc, i) => {
                                    return <li key={i}><Link to={`/informe/${doc.numero}`}>Informe nº {doc.numero}</Link></li>;
                                }) }
                            </ul>
                        </div>
                }
            </div>
            <div className="results" id="expedientes">
                {
                    results.expedientes.length === 0
                        ? <p>No se encontraron expedientes coincidentes.</p>
                        : <div>
                            {/*<h4>Expedientes:</h4>*/}
                            <p>{results.expedientes.length} expedientes:</p>
                            <ul>
                                { results.expedientes.map((doc, i) => {
                                    return <li key={i}><Link to={`/expediente/${doc.numero}`}>Expediente nº {doc.numero}</Link></li>;
                                }) }
                            </ul>
                        </div>
                }
            </div>
            <button id="limpiar" onClick={onClickHandler}>Limpiar</button>
        </div>
    );
    
}