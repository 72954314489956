import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import './Login.css';
import { selectAuthenticated } from './authSlice';
import { SERVER_LOGIN } from './authAPI'; 

export default function Login () {
    const userAuthenticated = useSelector(selectAuthenticated);

    useEffect(() => {
        if (userAuthenticated) {
            return <Navigate to="/" />;
        }
    }, [userAuthenticated]);


    // eslint-disable-next-line no-unused-vars
    function onClickHandler({target}) {
        window.open(SERVER_LOGIN, '_self');
    }

    return (
        <div className="login">
            <h2>Inicia sesión con tu cuenta de pinardi.com</h2>
            {/*  Esto entiendo que son mensajes de feedback que te manda Google 
			<% if (hasMessages) { %>
			<section class="messages">
				<% messages.forEach(function(message) { %>
				<p><%= message %></p>
				<% }); %>
			</section>
			<% } %>
         */}
            <button onClick={onClickHandler}>entrar con Google</button>
        </div>
    );
}