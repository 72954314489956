import { createStateSyncMiddleware, initStateWithPrevTab, withReduxStateSync } from 'redux-state-sync';
import thunk from 'redux-thunk'; 
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import expedienteReducer from '../features/expediente/expedienteSlice';
import informeReducer from '../features/informe/informeSlice';
import searchReducer from '../features/search/searchSlice';
import authReducer from '../features/auth/authSlice';
import numexpedientesReducer from '../features/numexpedientes/numexpedientesSlice';
import documentoReducer from '../features/expediente/documento/documentoSlice';
import buscarUsuarioReducer from '../features/usuario/buscar/buscarUsuarioSlice';
import usuarioReducer from '../features/usuario/datos/usuarioSlice';

/* export const store = configureStore({
  reducer: {
    expediente: expedienteReducer,
    informe: informeReducer,
    search: searchReducer,
    auth: authReducer
  }
}); */

const config = {
    // search will not be triggered in other tabs
    //blacklist: ['search'],
};
const middlewares = [
    createStateSyncMiddleware(config),
];

const rootReducer = combineReducers({ 
    expediente: expedienteReducer,
    informe: informeReducer,
    search: searchReducer,
    auth: authReducer,
    numexpedientes: numexpedientesReducer,
    documento: documentoReducer,
    usuario: usuarioReducer,
    buscarUsuario: buscarUsuarioReducer
});

export const store = configureStore({
    reducer: withReduxStateSync(rootReducer),
    middleware: [thunk, ...middlewares]
});

// init state with other tabs
initStateWithPrevTab(store);