/* eslint-disable no-undef */
//export const SERVER_PORT = 'http://localhost:3001/auth/google';
//export const SERVER_PORT = 'https://54.74.26.246:443/auth/google';
//import { SERVER_PORT } from '../../app/utils';

export const SERVER_LOGIN = process.env.REACT_APP_SERVER_URL + '/auth/google';

export const logToServer = async () => {
    //const response = await fetch('http://localhost:3001/auth/login', {
    const response = await fetch(process.env.REACT_APP_SERVER_URL + '/auth/login', {
        method: 'GET',
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': true
        }
    });
    return response;
};

export const logOutFromServer = async () => {
    //const response = await fetch('http://localhost:3001/auth/logout', {
    const response = await fetch(process.env.REACT_APP_SERVER_URL + '/auth/logout', {
        method: 'POST',
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': true
        }
    });
    return response;
};