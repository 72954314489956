import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './expediente.css';
import { selectAuthenticated, selectUser } from '../auth/authSlice';
import { STATUS, loadExpediente } from './expedienteSlice';
import Login from '../auth/Login';
import Spinner from '../../components/Spinner';


export default function Expediente () {
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const userAuthenticated = useSelector(selectAuthenticated);
    /* useEffect(() => {
        if (!userAuthenticated) {
            navigate('/login');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userAuthenticated]); */

    const { num } = useParams();
    
    const dispatch = useDispatch();

    const { expediente, status, error } = useSelector((state) => state.expediente);

    useEffect(()=>{
        dispatch(loadExpediente(num));
    }, [dispatch, num]);

    if (!userAuthenticated) {
        return <Login />;
    }

    if (status === STATUS.LOADING) {
        return <Spinner/>;
    }

    if (error === STATUS.COULDNT_LOAD) {
        return (
            <> 
                <div className='title'>
                    <h2>ERROR: No hay ningún expediente nº {num}</h2>
                </div>
                <button id='volver' onClick={() => navigate('/')}>Volver a inicio</button>
            </>
        );
    }

    return (
        <> 
            <div className='title'>
                <h2>Expediente nº {expediente.numero}</h2>
                <h3>Estado: 
                    { expediente.abierto 
                        ? ' Abierto'
                        : ' Cerrado' 
                    }
                </h3>
            </div>
            <button id='volver' onClick={() => navigate(-1)}>Atrás</button>
            <div className="buttons-fullpage">
                <button onClick={() => navigate(`/expediente/${expediente.numero}/ver`)}>Visualizar datos</button>
                { expediente.abierto && <button onClick={() => navigate(`/expediente/${expediente.numero}/editar`)}>Editar</button> }
                {/* expediente.abierto && <button onClick={() => navigate(`/expediente/${expediente.numero}/actuacion`)}>Añadir actuación</button> */}
                {
                    user && ('rol' in user) && user.rol.find((element) => element === 'admin')
                        ? expediente.abierto && <button onClick={() => navigate(`/expediente/${expediente.numero}/editar`)}>Revisar y cerrar</button>
                        : ''
                }
            </div>
        </>
    );
}