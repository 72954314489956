import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { buscaInformes, buscaExpedientes } from './searchAPI';
import { queryServer } from '../../app/utils';

export const STATUS = {
    NO_DATA: 'no data',
    SHOWING: 'showing',
    LOADING: 'loading',
    COULDNT_LOAD: 'couldn\'t load',
    NO_ERROR: 0
};

const initialState = {
    params: {}, // todos, termino, en, doc, anyo, plataforma
    results: {
        informes: [],
        expedientes: []
    },
    status: STATUS.NO_DATA,
    error: STATUS.NO_ERROR
};

/* async function asyncPromAll() {
    const resultArray = await Promise.all([asyncTask1(), asyncTask2(), asyncTask3(), asyncTask4()]);
    for (let i = 0; i<resultArray.length; i++){
      console.log(resultArray[i]);
    }
  } */

// async thunks
export const loadData = createAsyncThunk(
    'search/loadData',  
    async (params, { rejectWithValue }) => {
    // TODO: hacer las consultas paralelamente
    // de primeras busco tanto informes como expedientes
    // si hace falta discriminar ya lo haremos más adelante
        const responseInformes = await queryServer(buscaInformes, params, rejectWithValue);
        const responseExpedientes = await queryServer(buscaExpedientes, params, rejectWithValue);
        //const responseInformes = await queryServer(buscaInformes, params);
        //const responseExpedientes = await queryServer(buscaExpedientes, params);
        // TODO: convertir los datos paralelamente
        const dataInformes = await responseInformes.json();
        const dataExpedientes = await responseExpedientes.json();
        const data = {
            informes: dataInformes,
            expedientes: dataExpedientes
        };
        return data;
    }
);

// slice object
export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        clearResults: (state) => {
            state.results = {
                informes: [],
                expedientes: []
            };
            state.status = STATUS.NO_DATA;
            state.error = STATUS.NO_ERROR;
        }
    },
    extraReducers: (builder) => {
        builder
        // loadData
            .addCase(loadData.pending, (state) => {
                state.results = {
                    informes: [],
                    expedientes: []
                };
                state.status = STATUS.LOADING;
                state.error = STATUS.NO_ERROR;
            })
            .addCase(loadData.fulfilled, (state, action) => {
                state.results = action.payload;
                state.status = STATUS.SHOWING;
                state.error = STATUS.NO_ERROR;
            })
            .addCase(loadData.rejected, (state) => {
                state.status = STATUS.NO_DATA;
                state.error = STATUS.COULDNT_LOAD;
            });
    }
});

// selectors
export const selectResults = (state) => state.search.results;

// exports
export const { 
    clearResults
} = searchSlice.actions;
export default searchSlice.reducer;