/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { cambiaResponsable } from './usuarioAPI';
import { loadResponsables } from './buscar/buscarUsuarioSlice';

// eslint-disable-next-line no-unused-vars, react/prop-types
export default function EditarResponsable ({plataforma, oldEmail}) {
    const dispatch = useDispatch();
    const [showForm, setShowForm] = useState(false);
    const [inputValue, setInputValue] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [ loadingStatus, setLoadingStatus ] = useState('');
  
    function RequestStatus ({status}) {
        if (status === 'success') {
            // eslint-disable-next-line no-undef
            //return (<p className='docstatus'>✅ Documento cargado correctamente.</p>);
            return '';
        } else if (status === 'error') {
            return <div className='docstatus'>❌ No se puede ejecutar la acción.</div>;
        } else if (status === 'uploading'){
            return <div className='docstatus'>⏳ Comprobando...</div>;
        } else {
            return '';
        }
    }

    async function requestChange() {
        setLoadingStatus('uploading');
        const newEmail = inputValue;
        const data = { newEmail, oldEmail, plataforma };
        try {
            const response = await cambiaResponsable(data);
            if (response.ok) {
                dispatch(loadResponsables());
                setLoadingStatus('success');
                setInputValue('');
                setShowForm(false);
            } else {
                //const e = await response.text();
                const body = await response.json();
                const e = body.message;
                throw new Error(e);
            }
        } catch (error) {
            setLoadingStatus('error: ' + error);
            alert(error);
        }
    }
  
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (inputValue) {
            await requestChange();
        }
    };

    const handleButtonClick = ({target}) => {
        switch (target.id) {
        case 'cambiar':
            setShowForm(true);
            break;
        case 'cancelar':
            setShowForm(false);
            setInputValue('');
            setLoadingStatus('');
            break;
        default:
            break;
        }
        
    };

    return (
        <div>
            {!showForm ? (
                <button id="cambiar" onClick={handleButtonClick}>Cambiar</button>
            ) : (
                <form onSubmit={handleFormSubmit}>
                    <input
                        type="text"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                    <button type="submit">Guardar</button>
                    <button id="cancelar" onClick={handleButtonClick}>Cancelar</button>
                    <RequestStatus status={loadingStatus} />
                </form>
            )}
        </div>
    );
}