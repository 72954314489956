import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS, loadResponsables, selectBuscarUsuario } from './buscarUsuarioSlice';
import Spinner from '../../../components/Spinner';
import EditarResponsable from '../EditarResponsable';
import { plataformas } from '../../../app/utils';
//import { Link } from 'react-router-dom';
import './ListaUsuarios.css';

export default function ListaResponsables () {
    const { results, status, error } = useSelector(selectBuscarUsuario);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadResponsables());
    }, []);

    // eslint-disable-next-line no-unused-vars
    function onClickHandler({target}) {
        
    }

    const findUserForPlatform = (platform) => {
        if (results.length === 0) {
            return null;
        }
        const match = results.filter( (user) => user.responsable === platform );
        if (!match || match.length === 0) {
            return null;
        } else {
            return match[0].email;
        }
    };

    if (status === STATUS.LOADING) {
        return <Spinner/>;
    }

    if (error === STATUS.COULDNT_LOAD) {
        return (
            <div className='search-results'>
                <p>No se pudo completar la carga.</p>
            </div>
        );
    }

    return (
        <>
            <div className='search-results'> 
                <div className="user-results">
                    <div className="table-container">
                        <div className="table-row header">
                            <div className="table-cell">Plataforma</div>
                            <div className="table-cell">E-mail</div>
                            <div className="table-cell"></div>
                        </div>
                        {plataformas.filter((nombre) => (nombre.length > 0)).map((nombre, i) => (
                            <div key={i} className="table-row">
                                <div className="table-cell">{nombre}</div>
                                <div className="table-cell">
                                    { findUserForPlatform(nombre) }
                                    {/*<Link to={`/usuario/${item.email}`}>{item.email}</Link>*/}
                                </div>
                                <div className="table-cell"><EditarResponsable plataforma={nombre} oldEmail={findUserForPlatform(nombre)}/></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            
        </>
    );
    
}