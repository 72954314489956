import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { traeUsuario, enviaNuevoUsuario, actualizaUsuario, borraUsuario } from '../usuarioAPI';
import { queryServer } from '../../../app/utils';

export const STATUS = {
    NEW: 'new',
    SAVING: 'saving',
    SAVED: 'saved',
    LOADING: 'loading',
    DELETING: 'deleting',
    COULDNT_LOAD: 'couldn\'t load',
    COULDNT_SAVE: 'couldn\'t save',
    COULDNT_DELETE: 'couldn\'t delete',
    NO_ERROR: 0
};

const initialState = {
    usuario: {
        email: '',
        plataforma: 'todas',
        rol: []
    },
    status: STATUS.NEW,
    error: STATUS.NO_ERROR
};


// async thunks
export const loadUsuario = createAsyncThunk(
    'usuario/loadUsuario',  
    async (email, { rejectWithValue }) => {
        const response = await queryServer(traeUsuario, email, rejectWithValue);
        const data = await response.json();
        return data;
    }
);

export const persistUsuario = createAsyncThunk(
    'usuario/persistUsuario',
    async (_, { rejectWithValue, getState }) => {
        const { usuario, status } = getState().usuario;
        let response;
        if (status === STATUS.FIRST_SAVING) {
            response = await queryServer(enviaNuevoUsuario, usuario, rejectWithValue);
        } else {
            response = await queryServer(actualizaUsuario, usuario, rejectWithValue);
        }
        //return response;
        const data = response.status;
        return data;
    }
);

export const deleteUsuario = createAsyncThunk(
    'usuario/deleteUsuario',  
    async (email, { rejectWithValue }) => {
        const response = await queryServer(borraUsuario, email, rejectWithValue);
        const data = response.status;
        return data;
    }
);

// slice object
export const usuarioSlice = createSlice({
    name: 'usuario',
    initialState,
    reducers: {
        clearUsuario: (state) => {
            state.usuario = {
                email: '',
                plataforma: 'todas',
                rol: []
            };
            state.status = STATUS.NEW;
            state.error = STATUS.NO_ERROR;
        },
        editUsuario: (state, action) => {
            // NO: 
            // si hay alguna edición es en la pantalla donde
            // se editan todos los datos, así que bien podemos
            // pasar el objeto completo y sobreescribirlo
            // con los cambios que pueda haber en los distintos campos
            //state.data = action.payload;
            // SÍ:
            // actualizamos campos individuales
            const { key, value } = action.payload;
            state.usuario[key] = value;
            // state.status = STATUS.NEW; // se mantiene en NEW hasta que se guarda
        }
    },
    extraReducers: (builder) => {
        builder
        // loadUsuario
            .addCase(loadUsuario.pending, (state) => {
                state.usuario = {
                    email: '',
                    plataforma: 'todas',
                    rol: []
                };
                state.status = STATUS.LOADING;
                state.error = STATUS.NO_ERROR;
            })
            .addCase(loadUsuario.fulfilled, (state, action) => {
                state.usuario = action.payload;
                state.status = STATUS.SAVED;
                state.error = STATUS.NO_ERROR;
            })
            .addCase(loadUsuario.rejected, (state) => {
                state.status = STATUS.NEW;
                state.error = STATUS.COULDNT_LOAD;
            })
      
        // persistUsuario
            .addCase(persistUsuario.pending, (state) => {
                state.status === STATUS.NEW 
                    ? state.status = STATUS.FIRST_SAVING 
                    : state.status = STATUS.SAVING;
                state.error = STATUS.NO_ERROR;
            })
            .addCase(persistUsuario.fulfilled, (state, action) => {
                if (action.payload.status >= 300) {
                    state.status = STATUS.COULDNT_SAVE;
                    state.error = STATUS.COULDNT_SAVE;
                } else {
                    state.status = STATUS.SAVED;
                    state.error = STATUS.NO_ERROR;
                }
            })
            .addCase(persistUsuario.rejected, (state) => {
                state.status === STATUS.FIRST_SAVING 
                    ? state.status = STATUS.NEW 
                    : state.status = STATUS.SAVED;
                state.error = STATUS.COULDNT_SAVE;
            })
            
        // deleteUsuario
            .addCase(deleteUsuario.pending, (state) => {
                state.status = STATUS.DELETING;
                state.error = STATUS.NO_ERROR;
            })
            .addCase(deleteUsuario.fulfilled, (state) => {
                state.usuario = {
                    email: '',
                    plataforma: 'todas',
                    rol: []
                };
                state.status = STATUS.NEW;
                state.error = STATUS.NO_ERROR;
            })
            .addCase(deleteUsuario.rejected, (state) => {
                state.status = STATUS.SAVED;
                state.error = STATUS.COULDNT_DELETE;
            });
    }
});

// selectors
export const selectUsuario = (state) => state.usuario.usuario;

// exports
export const { 
    clearUsuario,
    editUsuario
} = usuarioSlice.actions;
export default usuarioSlice.reducer;
