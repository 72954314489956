import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './expediente.css';
import { selectAuthenticated } from '../auth/authSlice';
import { STATUS, loadExpediente } from './expedienteSlice';
import Login from '../auth/Login';
import Spinner from '../../components/Spinner';
import Actuaciones from './actuacion/Actuaciones';
import { escenarios } from '../../app/utils.js';

export default function VerExpediente () {
    const navigate = useNavigate();
    const userAuthenticated = useSelector(selectAuthenticated);
    /* useEffect(() => {
        if (!userAuthenticated) {
            navigate('/login');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userAuthenticated]); */

    const { num } = useParams();
    const dispatch = useDispatch();
    const { expediente, status, error } = useSelector((state) => state.expediente);

    useEffect(()=>{
        if (!isNaN(parseInt(num))) {
            dispatch(loadExpediente(num));
        } else {
            alert('Es necesario el número de expediente.');
            navigate('/');
        }
    }, [num]);

    if (!userAuthenticated) {
        return <Login />;
    }

    if (status === STATUS.LOADING) {
        return <Spinner/>;
    }

    if (error === STATUS.COULDNT_LOAD) {
        return (
            <> 
                <div className='title'>
                    <h2>ERROR: No hay ningún expediente nº {num}</h2>
                </div>
                <button id='volver' onClick={() => navigate('/')}>Volver a inicio</button>
            </>
        );
    }

    return (
        <> 
            <div className='title'>
                <h2>Expediente nº {num}</h2>
                <h3>Estado: 
                    { expediente.abierto 
                        ? ' Abierto'
                        : ' Cerrado' 
                    }
                </h3>
                <h3>Tipo de escenario: {escenarios[parseInt(expediente.escenario)-1]}</h3>
            </div>
            <button id="volver" onClick={() => navigate(-1)}>Atrás</button>

            <div className="buttons-fullpage">
                {expediente.abierto && <button onClick={() => navigate(`/expediente/${num}/editar`)}>Editar</button>}
            </div>
            <div className='content'>
                <form>
                    {/*<h3>Tipo de escenario:</h3>
                    <input readOnly type="text" id="escenario" name="escenario" value={escenarios[parseInt(expediente.escenario)-1]}/>*/}
                    <h3>Datos de registro:</h3>
                    <label htmlFor="plataforma">Plataforma:</label>
                    <input readOnly type="text" id="plataforma" name="plataforma" value={expediente.plataforma}/>    
                    <label htmlFor="responsable">Responsable de la plataforma / sistema de protección:</label>
                    <input readOnly type="text" id="responsable" name="responsable" value={expediente.responsable}/>
                    <label htmlFor="fecha_apertura">Fecha de apertura:</label>
                    <input readOnly type="date" id="fecha_apertura" name="fecha_apertura" value={expediente.fecha_apertura}/>
                    <label htmlFor="fecha_archivo">Fecha de archivo:</label>
                    <input readOnly type="date" id="fecha_archivo" name="fecha_archivo" value={expediente.fecha_archivo}/>
                    <label htmlFor="fecha_cierre">Fecha de cierre:</label>
                    <input readOnly type="date" id="fecha_cierre" name="fecha_cierre" value={expediente.fecha_cierre}/>
                    <h3>Registro de actuaciones:</h3>
                    <Actuaciones editable={false}/>            
                    <h3>Resultados:</h3>
                    <label htmlFor="medidas">Medidas adoptadas:</label>
                    <div className='textarea-content'>
                        <pre>{expediente.medidas || ''}</pre>
                    </div>
                    <label htmlFor="resultados">Resultados de las medidas:</label>
                    <div className='textarea-content'>
                        <pre>{expediente.resultados || ''}</pre>
                    </div>
                    <label htmlFor="motivo_cierre">Motivo de cierre del expediente:</label>
                    <div className='textarea-content'>
                        <pre>{expediente.motivo_cierre || ''}</pre>
                    </div>
                    <h3>Responsable del expediente:</h3>
                    <label htmlFor="nombre_responsable">Nombre:</label>
                    <input readOnly type="text" id="nombre_responsable" name="nombre_responsable" 
                        value={expediente.nombre_responsable || 'Aurora Martín'}/>
                    <label htmlFor="funcion_responsable">Función dentro del SdPI:</label>
                    <input readOnly type="text" id="funcion_responsable" name="funcion_responsable" 
                        value={expediente.funcion_responsable || 'Responsable del SdPI'}/>
                    <label htmlFor="fecha_firma">Fecha de firma:</label>
                    <input readOnly type="date" id="fecha_firma" name="fecha_firma" value={expediente.fecha_firma}/>
                
                </form>
            </div>
            <button onClick={() => navigate(-1)}>Atrás</button>
        </>
    );
}