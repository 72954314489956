import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import './Index.css';
import { selectAuthenticated, selectUser, login } from '../features/auth/authSlice';
import Login from '../features/auth/Login';
import Spinner from './Spinner';
import { STATUS, getTotalNum } from '../features/numexpedientes/numexpedientesSlice';

export default function Index () {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { /*num,*/ status } = useSelector((state) => state.numexpedientes);
    const authenticated = useSelector(selectAuthenticated);
    const user = useSelector(selectUser);
    
    useEffect(() => {
        if (!authenticated) {
            //navigate('/login');
            dispatch(login());
        }
    }, [authenticated]);

    /*
    useEffect(() => {
        dispatch(login());
    }, []);
    */

    
    useEffect(() => {
        dispatch(getTotalNum());
    }, []);
    

    if (!authenticated) {
        return <Login/>;
    }

    if (status === STATUS.LOADING) {
        return <Spinner/>;
    }

    return (
        <> 
            {/* status === STATUS.LOADED
                ? <p>Hay <span id='numexpedientes'>{num}</span> expedientes en el sistema.</p>
                : ''
    */}
            
            <h2>¿Qué necesitas hacer?</h2>

            <div className="buttons">
                <button onClick={() => navigate('/informe')}>Notificar un suceso</button>
                { 
                    user && 'rol' in user && user.rol.find((element) => element === 'intern' || element === 'extern')
                        ? <button onClick={() => navigate('/buscar')}>Buscar informe o expediente</button>
                        : ''
                }
                {
                    user && ('rol' in user) && user.rol.find((element) => element === 'admin')
                        ? <button onClick={() => navigate('/usuarios')}>Gestionar usuarios</button>
                        : ''
                }
            </div>
        </>
    );
}