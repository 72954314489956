import React from 'react';
import { useSelector } from 'react-redux';
import AgregarActuacion from './AgregarActuacion';
import Actuacion from './Actuacion';

// eslint-disable-next-line react/prop-types
export default function Actuaciones ({editable}) {
    const { expediente } = useSelector((state) => state.expediente);

    return (
        <div className="buildable-list">
            <div className="actuaciones">
                { expediente.actuaciones !== undefined && expediente.actuaciones.length > 0
                    ? expediente.actuaciones.map((actuacion, index) => (
                        <Actuacion key={index} index={index} editable={editable} />
                    ))
                    : <p>No se ha registrado ninguna actuación.</p>
                }
            </div>
            { editable
                ? <AgregarActuacion />
                : ''
            }
        </div>
    );
}