import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { traeNumexpedientes } from './numexpedientesAPI';
import { queryServer } from '../../app/utils';

export const STATUS = {
    LOADING: 'loading',
    LOADED: 'loaded',
    COULDNT_LOAD: 'couldn\'t load',
    NO_ERROR: 0
};

const initialState = {
    num: 0,
    status: STATUS.LOADED,
    error: STATUS.NO_ERROR
};


// async thunks
export const getTotalNum = createAsyncThunk(
    'numexpedientes/getTotalNum',  
    async (_, { rejectWithValue }) => {
        const response = await queryServer(traeNumexpedientes, _, rejectWithValue);
        const data = await response.json();
        return data;
    }
);

// slice object
export const numexpedientesSlice = createSlice({
    name: 'numexpedientes',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        // getTotalNum
            .addCase(getTotalNum.pending, (state) => {
                state.num = 0;
                state.status = STATUS.LOADING;
                state.error = STATUS.NO_ERROR;
            })
            .addCase(getTotalNum.fulfilled, (state, action) => {
                state.num = action.payload.totalnum;
                state.status = STATUS.LOADED;
                state.error = STATUS.NO_ERROR;
            })
            .addCase(getTotalNum.rejected, (state) => {
                state.status = STATUS.COULDNT_LOAD;
                state.error = STATUS.COULDNT_LOAD;
            });
    }
});

// selectors
export const selectTotalNum = (state) => state.numexpedientes.num;

// exports
/*
export const { 
 //
} = numexpedientesSlice.actions;
*/
export default numexpedientesSlice.reducer;
