import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS, /*removeResult,*/ clearResults, selectBuscarUsuario } from './buscarUsuarioSlice';
//import { deleteUsuario } from '../datos/usuarioSlice';
import Spinner from '../../../components/Spinner';
import { writeListFromArray } from '../../../app/utils';
import { Link } from 'react-router-dom';
import './ListaUsuarios.css';
import lapiz from '../../../lapiz.svg';
//import basura from '../../../basura.svg';

export default function ListaUsuarios () {
    const { results, status, error } = useSelector(selectBuscarUsuario);
    const dispatch = useDispatch();

    /*
    async function removeUser(email) {
        // eslint-disable-next-line no-unused-vars
        dispatch(deleteUsuario(email)).then((_result) => {
            //dispatch(loadData());
            // instead, remove line from results array
            dispatch(removeResult(email));
        });
    }
    */

    // eslint-disable-next-line no-unused-vars
    function onClickHandler({target}) {
        // limpiar resultados
        dispatch(clearResults());
    }

    if (status === STATUS.LOADING) {
        return <Spinner/>;
    }

    if (error === STATUS.COULDNT_LOAD) {
        return (
            <div className='search-results'>
                <h3>Resultados:</h3>
                <p>No se pudo completar la búsqueda.</p>
            </div>
        );
    }

    if (status === STATUS.NO_DATA) {
        return;
    }

    return (
        <>
            <div className='search-results'> 
                <div className='results-title' >
                    <h3>Resultados:</h3>
                </div>
                <button id="limpiar" onClick={onClickHandler}>Limpiar</button>
                <div className="user-results">
                    {
                        results.length === 0
                            ? <p>No se encontraron.</p>
                            : <div>
                                {/*<p>{results.length} usuarios:</p>*/}
                                <div className="table-container">
                                    <div className="table-row header">
                                        <div className="table-cell">E-mail</div>
                                        <div className="table-cell">Plataforma</div>
                                        <div className="table-cell">Rol especial</div>
                                        <div className="table-cell">Editar</div>
                                    </div>
                                    {results.map((item, i) => (
                                        <div key={i} className="table-row">
                                            <div className="table-cell"><Link to={`/usuario/${item.email}`}>{item.email}</Link></div>
                                            <div className="table-cell">{item.plataforma}</div>
                                            <div className="table-cell">{writeListFromArray(item.rol)}</div>
                                            <div className="table-cell"><Link to={`/usuario/${item.email}`}><button className="icon-button">
                                                <img src={lapiz} />
                                            </button></Link></div>
                                            {/*<div className="table-cell"><button className="icon-button" onClick={() => { removeUser(item.email); }}>
                                                <img src={basura} />
                                    </button></div>*/}
                                        </div>
                                    ))}
                                </div>
                            </div>
                    }
                </div>
                
            </div>
            
        </>
    );
    
}