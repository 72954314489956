import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import './usuario.css';
import { STATUS, clearUsuario, loadUsuario, editUsuario, persistUsuario, deleteUsuario } from './usuarioSlice';
import { selectBuscarUsuarioEmail } from '../buscar/buscarUsuarioSlice';
import { selectAuthenticated } from '../../auth/authSlice.js';
import Login from '../../auth/Login.js';
import Spinner from '../../../components/Spinner.js';
import { plataformas } from '../../../app/utils.js';
import {compruebaResponsable} from '../usuarioAPI.js';

export default function Usuario () {
    const navigate = useNavigate();
    const userAuthenticated = useSelector(selectAuthenticated);
    /* useEffect(() => {
        if (!userAuthenticated) {
            navigate('/login');
        }
    }, [userAuthenticated]); */

    const searchEmail = useSelector(selectBuscarUsuarioEmail);
    const { usuario, status, error } = useSelector((state) => state.usuario);
    const dispatch = useDispatch();

    const { login } = useParams();

    useEffect(()=>{
        if (login) {
            checkResponsable(login);
            dispatch(loadUsuario(login));
        } else {
            dispatch(clearUsuario());
            dispatch(editUsuario({ key: 'email', value: searchEmail }));
        }
    }, [login]);

    const [responsable, setResponsable] = useState(false);
    
    if (!userAuthenticated) {
        return <Login />;
    }

    // eslint-disable-next-line no-unused-vars
    async function checkResponsable(email) {
        try {
            const response = await compruebaResponsable(email);
            if (response.ok) {
                setResponsable(true);
            } else {
                const body = await response.json();
                const e = body.message;
                throw new Error(e);
            }
        } catch (error) {
            setResponsable(false);
            //alert(error);
        }
    }

    function onCheckboxChangeHandler(event) {
        const { value, checked } = event.target;
        //const rol = usuario.rol;
        let rol;
        if (checked) {
            rol = [...usuario.rol, value];
        } else {
            rol = usuario.rol.filter((item) => item !== value);
        }
        dispatch(editUsuario({
            key: 'rol',
            value: rol
        }));
    }

    function onChangeHandler({target}) {
        let userInput = target.value;
        const field = target.id;
        if (field === 'plataforma' && userInput === ''){
            userInput = 'todas';
        }
        dispatch(editUsuario({
            key: field,
            value: userInput
        }));
    }

    function onClickHandler({target}) {
        switch(target.id) {
        case 'volver':
            navigate('/usuarios');
            return;
        case 'responsables':
            navigate('/usuarios/responsables');
            return;
        case 'guardar':
            dispatch(persistUsuario());
            navigate(`/usuario/${usuario.email}`);
            break;
        case 'borrar':
            dispatch(deleteUsuario(usuario.email));
            navigate('/usuarios');
            break;
        }    
    }

    function newUserForm(){
        return (<>
            <h2>Añadiendo perfil con acceso a Jilguero</h2>
            <h3>Email:</h3>
            <input type="text" id="email" name="email"
                value={usuario.email || searchEmail}
                onChange={onChangeHandler}/>
        </>);
    }

    function editUserForm(){
        return (<>
            <h2>Editando el usuario <strong>{ usuario.email }</strong></h2>
        </>);
    }

    return (
        <> 
            { status === STATUS.SAVING 
                ? <>
                    <p>Guardando...</p>
                    <Spinner/>
                </>
                : ''
            }
            { error === STATUS.COULDNT_SAVE
                ? <>
                    <h2>ERROR: No se ha podido guardar.</h2>
                </>
                : ''
            }
            <button id="volver" onClick={onClickHandler}>Atrás</button>
            <div className='content'>
                <div className='user'>
                    <form>
                        { status === STATUS.NEW
                            ? newUserForm()
                            : editUserForm()
                        }
                        <h3>Plataforma:</h3>
                        { responsable === true
                            ? <h4>✅ Responsable de su plataforma</h4>
                            : ''
                        }
                        <select id="plataforma" name="plataforma"
                            value={usuario.plataforma || ''}
                            disabled={responsable}
                            onChange={onChangeHandler}>
                            {plataformas.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                        { responsable 
                            ? <button id="responsables" onClick={onClickHandler}>Gestionar responsables</button>
                            : ''
                        }
                        <h3>Permisos de acceso:</h3>
                        <div className='checkline'>
                            <input type="checkbox" id="rol-basic" name="rol" value="basic" 
                                checked='yes'
                                readOnly />
                            <label htmlFor="rol-admin"><strong>básico:</strong> enviar notificaciones</label>
                        </div>
                        <div className='checkline'>
                            <input type="checkbox" id="rol-admin" name="rol" value="admin" 
                                checked={usuario.rol.includes('admin')}
                                onChange={onCheckboxChangeHandler} />
                            <label htmlFor="rol-admin"><strong>administración:</strong> gestionar permisos de acceso</label>
                        </div>
                        <div className='checkline'>
                            <input type="checkbox" id="rol-admin" name="rol" value="extern" 
                                checked={usuario.rol.includes('extern')}
                                onChange={onCheckboxChangeHandler} />
                            <label htmlFor="rol-admin"><strong>gestión de expedientes externos:</strong> de personas ajenas y entre usuarias</label>
                        </div>
                        <div className='checkline'>
                            <input type="checkbox" id="rol-admin" name="rol" value="intern" 
                                checked={usuario.rol.includes('intern')}
                                onChange={onCheckboxChangeHandler} />
                            <label htmlFor="rol-admin"><strong>gestión de expedientes internos:</strong> de profesionales</label>
                        </div>
                    </form>
                </div>
            </div>
            <div className="buttons">
                <button id="guardar" onClick={onClickHandler}>Guardar</button>
                { status === STATUS.SAVED 
                    ? <button id="borrar" onClick={onClickHandler}>Eliminar del sistema</button>
                    : ''}
            </div>
        </>
    );
}