import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadData } from './searchSlice';
import { plataformas } from '../../app/utils.js';
import { selectUser } from '../auth/authSlice';

export default function SearchBox() {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const [ ignorarChecked, setIgnorarChecked ] = useState(false);
    const [ plataformaChecked, setPlataformaChecked ] = useState(false);
    const [ anyoChecked, setAnyoChecked ] = useState(false);
    const [ escenarioChecked, setEscenarioChecked ] = useState(false);
    
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        let input;
        switch(name) {
        case 'ignorar-box': 
            setIgnorarChecked(checked);
            input = document.getElementById('termino');
            if (!ignorarChecked) {
                input.style.backgroundColor='grey';
            } else {
                input.style.backgroundColor='white';
            }
            break;
        case 'plataforma-box': 
            setPlataformaChecked(checked);
            input = document.getElementById('filtro-plataforma');
            if (!plataformaChecked) {
                input.style.visibility='visible';
            } else {
                input.style.visibility='hidden';
            }
            break;
        case 'anyo-box': 
            setAnyoChecked(checked);
            input = document.getElementById('filtro-anyo');
            if (!anyoChecked) {
                input.style.visibility='visible';
            } else {
                input.style.visibility='hidden';
            }
            break;
        case 'escenario-box': 
            setEscenarioChecked(checked);
            input = document.getElementById('tipo-escenario');
            if (!escenarioChecked) {
                input.style.visibility='visible';
            } else {
                input.style.visibility='hidden';
            }
            break;
        }
    };

    function onClickHandler(event) {
        event.preventDefault();
        const target = event.target;
        let estado = document.getElementById('estado').value;
        let tipo = document.getElementById('tipo-anyo').value;
        let plataforma = user ? user.plataforma : '';
        let termino = '';
        let en = '';
        let anyo;
        const escenario = escenarioChecked ? document.getElementById('tipo-escenario').value : '';
        
        if (target.id === 'abiertos-ultimo') {
            estado = 'abiertos';
            tipo = 'notificacion';
            const today = new Date();
            anyo = today.getFullYear();
            // resto iguales
            plataforma = user ? user.plataforma : '';
            termino = '';
            en = '';
        }
        else {
            if (target.id === 'todos-btn') {
                termino = '';
            } else {
                termino = document.getElementById('termino').value;
                en = document.getElementById('en').value;   
            }
            if (plataformaChecked) {
                plataforma = document.getElementById('filtro-plataforma').value;
            }
            if (anyoChecked) {
                anyo = document.getElementById('filtro-anyo').value;
            }
        }
        
        dispatch(
            loadData({
                termino,
                en,
                plataforma,
                tipo,
                anyo,
                estado,
                escenario
            }),
        );
    }

    return (
        <>
            <h3 id='search-title'>Búsqueda en la base de datos:</h3>
            <form id='search-box'>
                <div className='searchfilters'>
                    <div className='filter' id="estado-div">
                        <label htmlFor="estado">Filtrar casos &nbsp;</label>
                        <select id="estado" name="estado">
                            <option value="ambos" selected>abiertos o cerrados</option>
                            <option value="abiertos">solo abiertos</option>
                            <option value="cerrados">solo cerrados</option>
                        </select>
                    </div>
                    <div className='filter' id="anyo-div">
                        <input type="checkbox" id="anyo-box" name="anyo-box" value="anyo-box" 
                            checked={anyoChecked}
                            onChange={handleCheckboxChange} />
                        <label htmlFor="anyo-box">Filtrar por año de &nbsp;</label>
                        <select id="tipo-anyo" name="tipo-anyo">
                            <option value="suceso" selected>suceso</option>
                            <option value="notificacion">notificación</option>
                            <option value="archivo">archivo</option>
                            <option value="cierre">cierre</option>
                        </select>
                        <input type="number" id="filtro-anyo" name="anyo" />
                    </div>
                    { user && user.rol.includes('extern') ? 
                        <div className='filter' id="escenario-div">
                            <input type="checkbox" id="escenario-box" name="escenario-box" value="escenario-box" 
                                checked={escenarioChecked}
                                onChange={handleCheckboxChange} />
                            <label htmlFor="escenario-box">Filtrar por escenario &nbsp;</label>
                            <select id="tipo-escenario" name="tipo-escenario">
                                <option value="1" selected>fuera de actividades</option>
                                <option value="2">entre iguales</option>
                                { user && user.rol.includes('intern') ?
                                    <option value="3">Cód. Conducta</option> : ''}
                            </select>
                        </div> : '' }
                    { 
                        user && user.plataforma === 'todas'
                            ? <div className='filter' id="plataforma-div">
                                <input type="checkbox" id="plataforma-box" name="plataforma-box" value="plataforma-box" 
                                    checked={plataformaChecked}
                                    onChange={handleCheckboxChange} />
                                <label htmlFor="plataforma-box">Filtrar por plataforma</label>
                                <select id="filtro-plataforma" name="plataforma" >
                                    {plataformas.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            : ''
                    }
                </div>
                <br/>
                <label htmlFor="termino">Término de búsqueda: </label>
                <input type="text" id="termino" name="termino" readOnly={ignorarChecked} />
                <label htmlFor="en"> en:</label>
                <select id="en" name="en">
                    <option value="numero">número de notificación</option>
                    <option value="campo">otros campos</option>
                </select>
                <button className='rapida-btn' id='buscar-btn' onClick={onClickHandler}>Buscar por término</button>
                <button className='rapida-btn' id='todos-btn' onClick={onClickHandler}>Recopilar todos</button>
            </form>
            <div className='rapida'>
                <p>Búsquedas rápidas:</p>
                <button className='rapida-btn' id='abiertos-ultimo' 
                    onClick={onClickHandler}>Expedientes no cerrados en el último año</button>
            </div>
        </>
    );
}
