import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prop-types
export default function DocLink ({documento}) {

    const pathExists = documento ? true : false;

    // no envía los datos de sesión: <Link to={`/${actuacion.documento_actuacion}`} target="_blank">Ver</Link>
    // no funciona: <Link onClick={() => navigate(`/${actuacion.documento_actuacion}`)} target="_blank">Ver</Link>
    // no se abre en otra pestaña y volver atrás me saca de la sesión: <button onClick={() => navigate(`/${actuacion.documento_actuacion}`)}>Ver</button>
    // <a href={`/${actuacion.documento_actuacion}`} target="_blank">Ver</a>
    function printLink(){
        const pathDocumento = pathExists ? documento.split('/') : [];
        const isDoc = pathExists & pathDocumento[0] === 'informe' ? false : true;
        if (isDoc) {
            //return (<a className='doclink' href={`https://jilguero-uploads.s3.eu-west-1.amazonaws.com/uploads/${pathDocumento[1]}/${pathDocumento[2]}`} target="_blank" rel="noreferrer">Ver</a>);
            // eslint-disable-next-line no-undef
            return (<a className='doclink' href={`${process.env.REACT_APP_SERVER_URL}/documentos/${pathDocumento[1]}/${pathDocumento[2]}`} target="_blank" rel="noreferrer">Ver</a>);
        } else {
            // eslint-disable-next-line no-undef
            return (<a className='doclink' href={`${process.env.REACT_APP_CLIENT_URL}/${documento}`} target="_blank" rel="noreferrer">Ver</a>);
        }
    }  

    return (
        <>
            <label htmlFor="documento_actuacion">Documento adjunto:</label>
            { pathExists
                ? printLink()
                : '--'
            }
        </>
    );

}

DocLink.propTypes = {
    documento: PropTypes.string.isRequired
};