/* eslint-disable no-undef */
import { createSlice } from '@reduxjs/toolkit';
//import { subeDocumento } from './documentoAPI';
//import { queryServer } from '../../../app/utils';


const initialState = {
    files: {
        new: {
            file: '',
            loadingStatus: 'no file', // 'no file' / 'success' / 'uploading' / 'error: ...'
        }
    },
    changes: []
};
/*
// async thunks
export const persistChanges = createAsyncThunk(
    'documento/persistChanges',  
    async (_, { rejectWithValue, getState }) => {
        const changes = getState().documento.changes;
        const update = changes.map(async (change) => {
            const response = await queryServer(borraDocumento, change.old, rejectWithValue);
            const result = await response.status;
            return result;
        });
        return update;
    }
);


export const undoChanges = createAsyncThunk(
    'documento/undoChanges',  
    async (_, { rejectWithValue, getState }) => {
        const changes = getState().documento.changes;
        const response = await queryServer(traeExpediente, num, rejectWithValue);
        const data = await response.json();
        return data;
    }
);
*/

// slice object
export const documentoSlice = createSlice({
    name: 'documento',
    initialState,
    reducers: {
        clearFiles: (state) => {
            state.files = {
                new: {
                    file: '',
                    loadingStatus: 'no file',
                }
            };
        },
        clearFile: (state, action) => {
            const which = action.payload.which;
            state.files[which] = {
                file: '',
                loadingStatus: 'no file',
            };
        },
        updateFile: (state, action) => {
            const which = action.payload.which;
            state.files[which].file = action.payload.file;
        },
        updateStatus: (state, action) => {
            const which = action.payload.which;
            state.files[which].loadingStatus = action.payload.loadingStatus;
        },
        clearChanges: (state) => {
            state.changes = [];
        },
        addChange: (state, action) => {
            state.changes.push({
                old: action.payload.old,
                new: action.payload.new
            });
        }
    }
});

// selectors
export const selectFiles = (state) => state.documento.files;
export const selectChanges = (state) => state.documento.changes;

// exports
export const {
    clearFiles, 
    clearFile,
    updateFile,
    updateStatus,
    clearChanges,
    addChange
} = documentoSlice.actions;
export default documentoSlice.reducer;
