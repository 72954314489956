import React from 'react';
import { useDispatch } from 'react-redux';
import { addProfesional } from './informeSlice';

export default function AgregarProfesional () {
    const dispatch = useDispatch();

    function onSubmitProfesionalHandler(e){
        e.preventDefault();
        const nombre_profesional = document.getElementById('nombre_profesional').value;
        const funcion_profesional = document.getElementById('funcion_profesional').value;
        const relacion_profesional = document.getElementById('relacion_profesional').value;
        const contacto_profesional = document.getElementById('contacto_profesional').value;
        dispatch(addProfesional({
            nombre_profesional,
            funcion_profesional,
            relacion_profesional,
            contacto_profesional
        }));
        document.getElementById('agregar_profesional_form').reset();
    }

    return (
        <>
            <h4>Para añadir un/a profesional, rellena los campos y pulsa &apos;AÑADIR&apos;:</h4>
            <div className='form-container'> 
                <form id="agregar_profesional_form">
                    <label htmlFor="nombre_profesional">Nombre:</label>
                    <input type="text" id="nombre_profesional" name="nombre_profesional"/>
                    <label htmlFor="funcion_profesional">Puesto de trabajo / función:</label>
                    <input type="text" id="funcion_profesional" name="funcion_profesional"/>
                    <br/>
                    <label htmlFor="relacion_profesional">Relación con la persona afectada:</label>
                    <input type="text" id="relacion_profesional" name="relacion_profesional"/>
                    <label htmlFor="contacto_profesional">Medios de contacto:</label>
                    <input type="text" id="contacto_profesional" name="contacto_profesional"/>
                    <button type="submit" onClick={onSubmitProfesionalHandler}>Añadir</button>
                </form>
            </div>
        </>
    );
}