import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthenticated } from '../auth/authSlice';
import Login from '../auth/Login';
import ListaResponsables from './buscar/ListaResponsables';


export default function AdminUsuarios () {
    const navigate = useNavigate();
    const userAuthenticated = useSelector(selectAuthenticated);

    if (!userAuthenticated) {
        return <Login />;
    }

    return (
        <> 
            <div className='title'>
                <h2>Administración de responsables de plataforma</h2>
            </div>
            <button id='volver' onClick={() => navigate(-1)}>Atrás</button>
            <ListaResponsables />
        </>
    );
}