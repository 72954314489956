import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './Search.css';
import SearchBox from './SearchBox';
import SearchResults from './SearchResults';
import Login from '../auth/Login';
import { selectAuthenticated } from '../auth/authSlice';

export default function Search() {
    const navigate = useNavigate();
    const userAuthenticated = useSelector(selectAuthenticated);

    // prefiero no perder los resultados de búsqueda
    /*
    // la primera vez que se carga la página, se limpian los datos
    // por si tuviéramos algo de antes
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(clearResults());
    }, []);
    */

    if (!userAuthenticated) {
        return <Login />;
    }

    return (
        <>
            <button id='volver' onClick={() => navigate('/')}>Volver a inicio</button>
            <SearchBox />
            <SearchResults />
        </>
    );
}