/* eslint-disable no-undef */
//const SERVER_PORT = 'http://localhost:3001/busqueda';
//import { SERVER_PORT } from '../../app/utils';


export const buscaInformes = async ({termino, en, plataforma, tipo, anyo, estado, escenario}) => {
    /* fetch('https://example.com?' + new URLSearchParams({
        foo: 'value',
        bar: 2,
    })) */
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/busqueda?`  + new URLSearchParams({
        doc: 'informe',
        termino,
        en,
        plataforma,
        tipo,
        anyo,
        estado,
        escenario
    }), {
        method: 'GET',
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Credentials': true
        }
    });
    return response;
};

export const buscaExpedientes = async ({termino, en, plataforma, tipo, anyo, estado, escenario}) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/busqueda?`  + new URLSearchParams({
        doc: 'expediente',
        termino,
        en,
        plataforma,
        tipo,
        anyo,
        estado,
        escenario
    }), {
        method: 'GET',
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Credentials': true
        }
    });
    return response;
};