/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS, editActuacion } from '../expedienteSlice';
import { subeDocumento /*, borraDocumento*/ } from './documentoAPI';
import DocLink from './DocLink';

export default function DocumentoEditable({index}) {
    const dispatch = useDispatch();
    //const expediente = useSelector(selectExpediente);
    const { expediente, status } = useSelector((state) => state.expediente);
    const actuacion = expediente.actuaciones[index];
    const [ changing, setChanging ] = useState(false);
    const [ loadingStatus, setLoadingStatus ] = useState('no file');
    //const [ prev, setPrev ] = useState('');

    function prepareNewFile(){
        setChanging(true);
    }

    function cancelNewFile(){
        setChanging(false);
    }
        
    async function uploadFile(ref) {
        if (!ref) {
            return;
        }
        //setPrev(actuacion.documento_actuacion);
        setLoadingStatus('uploading');
        const formData = new FormData();
        formData.append('documento', ref);

        try {
            const response = await subeDocumento(formData);
            if (response.ok) {
                setLoadingStatus('success');
                const filename = await response.text();
                const convertedFilename = filename.replace('\\', '/');
                //setPrev('');
                setChanging(false);
                dispatch(editActuacion({
                    index,
                    name: 'documento_actuacion',
                    value: 'documentos/'+convertedFilename
                }));
                setChanging(false);
            } else {
                const e = await response.text();
                throw new Error(e);
            }
        } catch (error) {
            setLoadingStatus('error: ' + error);
            alert(`Ha sucedido un error: ${error}`);
        }
    }

    // eslint-disable-next-line no-unused-vars
    /*
    async function removeFile() {
        if (!file) {
            return true;
        }
        
        try {
            const response = await borraDocumento(file);
            if (response.ok) {
                dispatch(clearFile({ which: index }));
                return true;
            } else {
                const e = await response.text();
                throw new Error(e);
            }
                
        } catch (error) {
            dispatch(updateStatus({
                which: index,
                loadingStatus: 'error: '+error 
            }));
            return false;
        }
    }
    */
    
    async function onClickHandler(e) {
        e.preventDefault();
        let input;
        switch (e.target.id) {
        case 'cambiar':
            prepareNewFile();
            break;
        case 'subir':
            input = document.getElementById(`documento_actuacion-${index}`);
            if (input.files){
                await uploadFile(input.files[0]);
            }
            break;
        case 'cancelar':
            cancelNewFile();
            break;
        default:
            break;
        }
    } 

    function DocStatus ({status}) {
        if (status === 'success') {
            // eslint-disable-next-line no-undef
            //return (<p className='docstatus'>✅ Documento cargado correctamente.</p>);
            return '';
        } else if (status === 'error') {
            return <div className='docstatus'>❌ No se pudo cargar el documento.</div>;
        } else if (status === 'uploading'){
            return <div className='docstatus'>⏳ Cargando documento...</div>;
        } else {
            return '';
        }
    }

    if (changing) {
        return (
            <div className='docform'>
                <div>
                    <label htmlFor="documento_actuacion">Documento adjunto:</label>
                    <input type="file" id={`documento_actuacion-${index}`} name="documento_actuacion"/>
                </div>
                <div>
                    <button id="subir" onClick={onClickHandler}>Subir documento</button>
                    <button id="cancelar" onClick={onClickHandler}>Cancelar</button>
                </div>
                <DocStatus status={loadingStatus} />
            </div>
        );
    } else {
        return (
            <div className='docform'>
                <div>
                    <DocLink documento={actuacion.documento_actuacion} />
                    { ! actuacion.documento_actuacion
                        ? <button id="cambiar" onClick={onClickHandler}>Cambiar</button>
                        : ''
                    }
                    { actuacion.documento_actuacion && actuacion.documento_actuacion.split('/')[0] !== 'informe'
                        ? <button id="cambiar" onClick={onClickHandler}>Cambiar</button>
                        : ''
                    }
                </div>
                <div>
                    { loadingStatus === 'success' && status !== STATUS.SAVED
                        ? <div className='reminder'>* Recuerda guardar el expediente para registrar el cambio de documento.</div>
                        : ''
                    }
                </div>
            </div>
        );
    }
}