import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
//import expedientes from '../../mocks/expedientes';
import { traeExpediente, enviaNuevoExpediente, actualizaExpediente } from './expedienteAPI';
import { queryServer } from '../../app/utils';

export const STATUS = {
    NEW: 'new',
    FIRST_SAVING: 'first time saving',
    FIRST_SAVED: 'first time saved',
    LOADING: 'loading',
    UNSAVED: 'unsaved',
    SAVING: 'saving',
    SAVED: 'saved',
    COULDNT_LOAD: 'couldn\'t load',
    COULDNT_SAVE: 'couldn\'t save',
    NO_ERROR: 0
};

const initialState = {
    expediente: {},
    status: STATUS.NEW,
    error: STATUS.NO_ERROR
};

// async thunks
export const loadExpediente = createAsyncThunk(
    'expediente/loadExpediente',  
    async (num, { rejectWithValue }) => {
        const response = await queryServer(traeExpediente, num, rejectWithValue);
        const data = await response.json();
        return data;
    }
);

export const persistExpediente = createAsyncThunk(
    'expediente/persistExpediente',
    async (_, { rejectWithValue, getState }) => {
        const { expediente, status } = getState().expediente;
        let response;
        if (status === STATUS.FIRST_SAVING) {
            response = await queryServer(enviaNuevoExpediente, expediente, rejectWithValue);
        } else {
            response = await queryServer(actualizaExpediente, expediente, rejectWithValue);
        }
        //return response;
        const data = response.status;
        return data;
    }
);

// slice object
export const expedienteSlice = createSlice({
    name: 'expediente',
    initialState,
    reducers: {
        clearExpediente: (state) => {
            state.expediente = {};
            state.status = STATUS.NEW;
            state.error = STATUS.NO_ERROR;
        },
        closeExpediente: (state) => {
            state.expediente.abierto = false;
            state.expediente.fecha_cierre = (new Date()).toLocaleDateString('fr-CA');
            state.status = STATUS.UNSAVED;
        },
        editExpediente: (state, action) => {
            // NO: 
            // si hay alguna edición es en la pantalla donde
            // se editan todos los datos, así que bien podemos
            // pasar el objeto completo y sobreescribirlo
            // con los cambios que pueda haber en los distintos campos
            //state.data = action.payload;
            // SÍ:
            // actualizamos campos individuales
            const { key, value } = action.payload;
            state.expediente[key] = value;
            if (state.status !== STATUS.NEW)
                state.status = STATUS.UNSAVED;
        },
        editActuacion: (state, action) => {
            const { index, name, value } = action.payload;
            const actuacion = state.expediente.actuaciones[index];
            actuacion[name] = value;
            state.status = STATUS.UNSAVED;
        },
        addActuacion: (state, action) => {
            // las actuaciones se añaden una a una en un formulario
            // individual
            state.expediente.actuaciones.push(action.payload);
            state.status = STATUS.UNSAVED;
        }
    },
    extraReducers: (builder) => {
        builder
        // loadExpediente
            .addCase(loadExpediente.pending, (state) => {
                state.expediente = {};
                state.status = STATUS.LOADING;
                state.error = STATUS.NO_ERROR;
            })
            .addCase(loadExpediente.fulfilled, (state, action) => {
                state.expediente = action.payload;
                state.status = STATUS.SAVED;
                state.error = STATUS.NO_ERROR;
            })
            .addCase(loadExpediente.rejected, (state) => {
                state.status = STATUS.NEW;
                state.error = STATUS.COULDNT_LOAD;
            })
      
        // persistExpediente
            .addCase(persistExpediente.pending, (state) => {
                state.status === STATUS.NEW 
                    ? state.status = STATUS.FIRST_SAVING 
                    : state.status = STATUS.SAVING;
                state.error = STATUS.NO_ERROR;
            })
            .addCase(persistExpediente.fulfilled, (state) => {
                state.status === STATUS.FIRST_SAVING 
                    ? state.status = STATUS.FIRST_SAVED 
                    : state.status = STATUS.SAVED;
                state.error = STATUS.NO_ERROR;
            })
            .addCase(persistExpediente.rejected, (state) => {
                state.status === STATUS.FIRST_SAVING 
                    ? state.status = STATUS.NEW 
                    : state.status = STATUS.UNSAVED;
                state.error = STATUS.COULDNT_SAVE;
            });
    }
});

// selectors
export const selectExpediente = (state) => state.expediente.expediente;

// exports
export const { 
    clearExpediente,
    closeExpediente,
    editExpediente,
    editActuacion,
    addActuacion
} = expedienteSlice.actions;
export default expedienteSlice.reducer;
