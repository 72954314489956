/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editActuacion, selectExpediente } from '../expedienteSlice';
import DocLink from '../documento/DocLink';
import DocumentoEditable from '../documento/DocumentoEditable';

export default function Actuacion ({index, editable}) {
    const dispatch = useDispatch();
    const expediente = useSelector(selectExpediente);
    const actuacion = expediente.actuaciones[index];

    function onChangeHandler({target}) {
        const name = target.name;
        const value = target.value;
        dispatch(editActuacion({
            index,
            name,
            value
        }));
    }

    return (
        <div key={`${index}`}  className='actuacion'>
            <p>{`${index+1}.`}</p>
            <div className="actuacion-data">
                <label htmlFor={`fecha_actuacion-${index}`}>Fecha:</label>
                { editable
                    ? <input type="date" id={`fecha_actuacion-${index}`} name="fecha_actuacion" 
                        value={actuacion.fecha_actuacion || ''}
                        onChange={onChangeHandler}/>
                    : <input type="date" id={`fecha_actuacion-${index}`} name="fecha_actuacion"
                        value={actuacion.fecha_actuacion}/>
                }
                <label htmlFor={`descripcion_actuacion-${index}`}>Descripción de la actuación:</label>
                { editable
                    ? <textarea id={`descripcion_actuacion-${index}`} name="descripcion_actuacion"
                        onChange={onChangeHandler}>
                        {actuacion.descripcion_actuacion || ''}
                    </textarea>
                    : <div className='textarea-content' id={`descripcion_actuacion-${index}`}>
                        <pre>{actuacion.descripcion_actuacion || ''}</pre>
                    </div>
                }
                {/*<label htmlFor={`documento_actuacion-${index}`}>Documento adjunto:</label>*/}
                { editable
                    ? <DocumentoEditable index={index} />
                    : <DocLink documento={actuacion.documento_actuacion} />
                }
                <div className='field'>
                    <label htmlFor={`responsable_actuacion-${index}`}>Responsable:</label>
                    { editable
                        ? <input type="text" id={`responsable_actuacion-${index}`} name="responsable_actuacion"
                            value={actuacion.responsable_actuacion || ''}
                            onChange={onChangeHandler}/>
                        : <input type="text" id={`responsable_actuacion-${index}`} name="responsable_actuacion"
                            value={actuacion.responsable_actuacion}/>
                    }
                </div>
            </div>
        </div>
    );

}