/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthenticated } from '../features/auth/authSlice';

//const SERVER = 'http://localhost:3001/documentos';
//import { SERVER_PORT } from '../app/utils';

export default function Documento () {
    const navigate = useNavigate();
    const userAuthenticated = useSelector(selectAuthenticated);
    useEffect(() => {
        if (!userAuthenticated) {
            navigate('/login');
        }
    }, [userAuthenticated]);

    const { folder, doc } = useParams();
    useEffect(() => {
        // 👇️ redirects to an external URL
        window.location.replace(`${process.env.REACT_APP_SERVER_URL}/documentos/${folder}/${doc}`);
    }, []);
    
    return <p>Redireccionando a {`${process.env.REACT_APP_SERVER_URL}/documentos/${folder}/${doc}`}</p>;
}