/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addActuacion } from '../expedienteSlice';
import SubirDocumento from '../documento/SubirDocumento';
import { selectFiles, clearFile } from '../documento/documentoSlice';

//const SERVER = 'http://localhost:3001';
//import { SERVER_PORT } from '../../app/utils';
//const SERVER_UPLOAD = `${process.env.REACT_APP_SERVER_URL}/docs`;

export default function AgregarActuacion () {
    const dispatch = useDispatch();
    const { file, loadingStatus } = useSelector(selectFiles).new;

    function onSubmitHandler(e) {
        e.preventDefault();
        const fecha_actuacion = document.getElementById('fecha_actuacion').value;
        if (fecha_actuacion.length == 0) {
            alert('Atención: no se puede añadir la actuación sin fecha.');
            return;
        }
        if (loadingStatus !== 'success') {
            alert('Atención: añadiendo actuación sin documento asociado.');
        }
        
        const descripcion_actuacion = document.getElementById('descripcion_actuacion').value;
        const documento_actuacion = file ? 'documentos/'+file : '';
        const responsable_actuacion = document.getElementById('responsable_actuacion').value;

        dispatch(addActuacion({
            fecha_actuacion,
            descripcion_actuacion,
            documento_actuacion,
            responsable_actuacion
        }));
        dispatch(clearFile({ which: 'new' }));
        document.getElementById('agregar_actuacion_form').reset();
    }

    return (
        <>
            <h4>Para añadir una nueva actuación al expediente, rellena los campos y pulsa &apos;AÑADIR&apos;, luego guarda los cambios al final del formulario:</h4>
            <div className='form-container'>  
                <form id="agregar_actuacion_form">
                    <label htmlFor="fecha_actuacion">Fecha:</label>
                    <input type="date" id="fecha_actuacion" name="fecha_actuacion"/><br/>
                    <label htmlFor="descripcion_actuacion">Descripción de la actuación:</label>
                    <textarea id="descripcion_actuacion" name="descripcion_actuacion"/><br/>
                    <SubirDocumento/><br/>
                    <label htmlFor="responsable_actuacion">Responsable:</label>
                    <input type="text" id="responsable_actuacion" name="responsable_actuacion"/><br/>
                    <button type="submit" onClick={onSubmitHandler}>Añadir</button>
                </form>
            </div>
        </>
    );
}