import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editEmail, selectBuscarUsuarioEmail } from './buscarUsuarioSlice';

// eslint-disable-next-line react/prop-types
export default function BuscarUsuario({onClickHandler}) {
    const searchEmail = useSelector(selectBuscarUsuarioEmail);
    const dispatch = useDispatch();

    function onChangeHandler({target}) {
        const userInput = target.value;
        //const field = target.id;
        dispatch(editEmail({
            key: 'email',
            value: userInput
        }));
    }

    return (
        <>
            {/*<h3 id='search-title'>Búsqueda en la base de datos:</h3>*/}
            <form id='search-box'>
                <label htmlFor="termino">Login de usuario: </label>
                <input type="text" id="termino" name="termino"
                    value={searchEmail || ''}
                    onChange={onChangeHandler} />
                <button className="boton-barra" id="buscar" onClick={onClickHandler}>Buscar</button>
                <button className="boton-barra" id="crear" onClick={onClickHandler}>Añadir</button>
            </form>
        </>
    );
}
