/* eslint-disable no-undef */

export const subeDocumento = async (file) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/documentos`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Credentials': true
        },
        body: file,
    });
    return response;
};

export const borraDocumento = async (file) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/documentos/${file}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Credentials': true
        }
    });
    return response;
};