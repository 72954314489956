import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../pinardi.jpg';
import jilguero from '../jilguero.png';
import { selectAuthenticated, selectUser, logout } from '../features/auth/authSlice';

export default function Header () {
    const userAuthenticated = useSelector(selectAuthenticated);
    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    // eslint-disable-next-line no-unused-vars
    function onClickHandler(e) {
        dispatch(logout());
    }

    return (
        <header>
            <Link to="/"><img className="logo" alt="Pinardi" src={logo} /></Link>
            <Link to="/"><h1>
                Sistema de protección de niños, niñas, adolescentes
                <br />y personas en situación de vulnerabilidad
            </h1></Link>
            <div id='logo'>
                <Link to="/"><img id='jilguero' alt="Jilguero" src={jilguero} /></Link>
                <Link to="/"><h1>Jilguero</h1></Link>
            </div>
            { userAuthenticated
                ?  <div className="logged-user">
                    <div className="user-blob">
                        <p>¡Hola!<br/> {user.name}</p>
                    </div>
                    <button id='salir' onClick={onClickHandler}>SALIR</button>
                </div>
                :  ''
            }
            <hr/>
        </header>
    );
}
