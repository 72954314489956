import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editProfesional } from './informeSlice';
import AgregarProfesional from './AgregarProfesional';

// eslint-disable-next-line react/prop-types
export default function Profesionales ({editable}) {
    const dispatch = useDispatch();
    const { informe } = useSelector((state) => state.informe);

    function onChangeProfesionalHandler({target}){
        // e.g.: target.id = nombre_profesional-0
        const idArray = target.id.split('-');
        const name = idArray[0];
        const pos = idArray[1];
        const value = target.value;
        dispatch(editProfesional({
            pos,
            name,
            value
        }));
    }

    function printProfesional(profesional, i){
        return (
            <div key={`${i}`} className="profesional">
                <p>{`${i+1}.`}</p>
                <div className="profesional-data">
                    <label htmlFor={`nombre_profesional-${i}`}>Nombre:</label>
                    { editable
                        ? <input type="text" id={`nombre_profesional-${i}`} name={`nombre_profesional-${i}`} 
                            value={informe.profesionales[i].nombre_profesional || ''}
                            onChange={onChangeProfesionalHandler}/>
                        : <input type="text" id={`nombre_profesional-${i}`} name={`nombre_profesional-${i}`} 
                            value={informe.profesionales[i].nombre_profesional}/>
                    }
    
                    <label htmlFor={`funcion_profesional-${i}`}>Puesto de trabajo / función:</label>
                    { editable
                        ? <input type="text" id={`funcion_profesional-${i}`} name={`funcion_profesional-${i}`} 
                            value={informe.profesionales[i].funcion_profesional || ''}
                            onChange={onChangeProfesionalHandler}/>
                        : <input type="text" id={`funcion_profesional-${i}`} name={`funcion_profesional-${i}`} 
                            value={informe.profesionales[i].funcion_profesional}/>
                    }
                
                    <label htmlFor={`relacion_profesional-${i}`}>Relación con la persona afectada:</label>
                    { editable
                        ? <input type="text" id={`relacion_profesional-${i}`} name={`relacion_profesional-${i}`} 
                            value={informe.profesionales[i].relacion_profesional || ''}
                            onChange={onChangeProfesionalHandler}/>
                        : <input type="text" id={`relacion_profesional-${i}`} name={`relacion_profesional-${i}`} 
                            value={informe.profesionales[i].relacion_profesional}/>
                    }

                    <label htmlFor={`contacto_profesional-${i}`}>Relación con la persona afectada:</label>
                    { editable
                        ? <input type="text" id={`contacto_profesional-${i}`} name={`contacto_profesional-${i}`} 
                            value={informe.profesionales[i].contacto_profesional || ''}
                            onChange={onChangeProfesionalHandler}/>
                        : <input type="text" id={`contacto_profesional-${i}`} name={`contacto_profesional-${i}`} 
                            value={informe.profesionales[i].contacto_profesional}/>
                    }
                </div>
            </div>
        );
    }

    return (
        <div className="buildable-list">
            <div className='profesionales'>
                { informe.profesionales !== undefined && informe.profesionales.length > 0
                    ? informe.profesionales.map((profesional, i)=> (
                        printProfesional(profesional, i)
                    ))
                    : <p>No se ha registrado ninguna persona.</p>
                }
            </div><br/><br/>
            { editable
                ? <AgregarProfesional />
                : ''
            }
        </div>
    );
}