import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { buscaUsuarios, traeResponsables } from '../usuarioAPI';
import { queryServer } from '../../../app/utils';

export const STATUS = {
    NO_DATA: 'no data',
    SHOWING: 'showing',
    LOADING: 'loading',
    COULDNT_LOAD: 'couldn\'t load',
    NO_ERROR: 0
};

const initialState = {
    //params: {}, // todos, termino
    email: '',
    todos: 'no',
    results: [],
    status: STATUS.NO_DATA,
    error: STATUS.NO_ERROR
};

// async thunks
export const loadData = createAsyncThunk(
    'buscarUsuario/loadData',  
    async (params, { rejectWithValue }) => {
        const response = await queryServer(buscaUsuarios, params, rejectWithValue);
        const data = await response.json();
        return data;
    }
);

export const loadResponsables = createAsyncThunk(
    'buscarUsuario/loadResponsables',  
    async (_, { rejectWithValue }) => {
        const response = await queryServer(traeResponsables, null, rejectWithValue);
        const data = await response.json();
        return data;
    }
);

// slice object
export const buscarUsuarioSlice = createSlice({
    name: 'buscarUsuario',
    initialState,
    reducers: {
        clearResults: (state) => {
            state.email = '';
            state.todos = 'no';
            state.results = [];
            state.status = STATUS.NO_DATA;
            state.error = STATUS.NO_ERROR;
        },
        editEmail: (state, action) => {
            state.email = action.payload.value;
        },
        selectAll: (state) => {
            state.todos = 'si';
        },
        unselectAll: (state) => {
            state.todos = 'no';
        },
        // eslint-disable-next-line no-unused-vars
        removeResult: (state, action) => {
            state.results = state.results.filter((value) => value.email !== action.payload );
            //state.results = [];
        }
    },
    extraReducers: (builder) => {
        builder
        // loadData
            .addCase(loadData.pending, (state) => {
                state.results = [];
                state.status = STATUS.LOADING;
                state.error = STATUS.NO_ERROR;
            })
            .addCase(loadData.fulfilled, (state, action) => {
                state.results = action.payload;
                state.status = STATUS.SHOWING;
                state.error = STATUS.NO_ERROR;
            })
            .addCase(loadData.rejected, (state) => {
                state.status = STATUS.NO_DATA;
                state.error = STATUS.COULDNT_LOAD;
            })
        // loadResponsables
            .addCase(loadResponsables.pending, (state) => {
                state.results = [];
                state.status = STATUS.LOADING;
                state.error = STATUS.NO_ERROR;
            })
            .addCase(loadResponsables.fulfilled, (state, action) => {
                state.results = action.payload;
                state.status = STATUS.SHOWING;
                state.error = STATUS.NO_ERROR;
            })
            .addCase(loadResponsables.rejected, (state) => {
                state.status = STATUS.NO_DATA;
                state.error = STATUS.COULDNT_LOAD;
            });
    }
});

// selectors
//export const selectResults = (state) => state.buscarUsuario.results;
export const selectBuscarUsuario = (state) => state.buscarUsuario;
export const selectBuscarUsuarioEmail = (state) => state.buscarUsuario.email;

// exports
export const { 
    clearResults,
    editEmail,
    selectAll,
    unselectAll,
    removeResult
} = buscarUsuarioSlice.actions;
export default buscarUsuarioSlice.reducer;