import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthenticated } from '../auth/authSlice';
import { STATUS, editExpediente, closeExpediente, loadExpediente, persistExpediente } from './expedienteSlice';
import Login from '../auth/Login';
import Spinner from '../../components/Spinner';
import EditFormExpediente from './EditFormExpediente';

export default function EditarExpediente () {
    const navigate = useNavigate();
    const userAuthenticated = useSelector(selectAuthenticated);
    /* useEffect(() => {
        if (!userAuthenticated) {
            navigate('/login');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userAuthenticated]); */
    
    // si no recibo ningún número de expediente,
    // se trata de añadir y no de editar
    const {num} = useParams();
    const dispatch = useDispatch();
    const { expediente, status, error } = useSelector((state) => state.expediente);

    useEffect(()=>{
        if (!isNaN(parseInt(num))) {
            if (status !== STATUS.UNSAVED) {
                dispatch(loadExpediente(num));
            }
        } 
    }, [num]);

    useEffect(() => {
        if (status === STATUS.SAVED && !(expediente.abierto)) {
            navigate(`/expediente/${expediente.numero}/ver`);
        }
    }, [status]);

    if (!userAuthenticated) {
        return <Login />;
    }
    
    function onClickHandler({target}) {
        if (target.id === 'expediente') {
            navigate(`/expediente/${num}`);
            return;
        }
        if (target.id==='cerrar'){
            dispatch(closeExpediente());
        }
        /*
        // Para asegurarnos de que se guarda el escenario
        const field = document.getElementById('escenario');
        const userInput = field.value;
        //const field = input.id;
        dispatch(editExpediente({
            key: 'escenario',
            value: userInput
        }));
        */
        if (!expediente.nombre_responsable) {
            dispatch(editExpediente({
                key: 'nombre_responsable',
                value: 'Aurora Martín'
            }));
        }
        if (!expediente.funcion_responsable) {
            dispatch(editExpediente({
                key: 'funcion_responsable',
                value: 'Responsable del SdPI'
            }));
        }
        dispatch(persistExpediente());
    }    

    if (status === STATUS.LOADING) {
        return <Spinner/>;
    }

    if (error === STATUS.COULDNT_LOAD) {
        return (
            <> 
                <div className='title'>
                    <h2>ERROR: No hay ningún expediente nº {num}</h2>
                </div>
                <button id='volver' onClick={() => navigate('/')}>Volver a inicio</button>
            </>
        );
    }

    return <EditFormExpediente num={num} onClickHandler={onClickHandler} />;
}