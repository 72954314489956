import React from 'react';
import { useDispatch } from 'react-redux';
import { addInvolucrada } from './informeSlice';

export default function AgregarInvolucrada () {
    const dispatch = useDispatch();

    function onSubmitInvolucradaHandler(e){
        e.preventDefault();
        const nombre_involucrada = document.getElementById('nombre_involucrada').value;
        const funcion_involucrada = document.getElementById('funcion_involucrada').value;
        const relacion_involucrada = document.getElementById('relacion_involucrada').value;
        dispatch(addInvolucrada({
            nombre_involucrada,
            funcion_involucrada,
            relacion_involucrada
        }));
        document.getElementById('agregar_involucrada_form').reset();
    }

    return (
        <>
            <h4>Para añadir una persona involucrada, rellena los campos y pulsa &apos;AÑADIR&apos;:</h4>
            <div className='form-container'> 
                <form id="agregar_involucrada_form">
                    <label htmlFor="nombre_involucrada">Nombre:</label>
                    <input type="text" id="nombre_involucrada" name="nombre_involucrada"/>
                    <label htmlFor="funcion_involucrada">Puesto de trabajo / función:</label>
                    <input type="text" id="funcion_involucrada" name="funcion_involucrada"/>
                    <label htmlFor="relacion_involucrada">Relación con la persona afectada:</label>
                    <input type="text" id="relacion_involucrada" name="relacion_involucrada"/>
                    <button type="submit" onClick={onSubmitInvolucradaHandler}>Añadir</button>
                </form>
            </div>
        </>
    );
}